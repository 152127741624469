import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const getRandomData = () => {
  const data = [];
  for (let i = 0; i < 12; i++) {
    data.push(Math.floor(Math.random() * 20));
  }
  return data;
};

const getLast12Months = () => {
  const months = [];
  const currentDate = new Date();
  currentDate.setDate(1); // Set to the first day of the month to avoid issues with month-end dates

  for (let i = 0; i < 12; i++) {
    months.push(
      currentDate.toLocaleString("default", { month: "short", year: "numeric" })
    );
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return months.reverse();
};

const ZendeskStackBarChart = () => {
  const months = getLast12Months();

  const series = [
    {
      name: "Number of Question Asked",
      data: getRandomData(),
    },
    {
      name: "Number of Tickets Ally Solved",
      data: getRandomData(),
    },
    {
      name: "Number of  Tickets Ally Escalated",
      data: getRandomData(),
    },
    {
      name: "Pending Tickets",
      data: getRandomData(),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      background: "#fff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    grid: {
      show: false,
    },
    colors: ["#00bcd4", "#8bc34a", "#ff5722", "#FFF4E5"],
    xaxis: {
      categories: getLast12Months(),
      labels: {
        style: {
          colors: "black",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false, // Remove the x-axis ticks
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "black",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "black",
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["gray"],
      },
      background: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
      },
    },

  };

  return (
    <div className="col-12 d-flex">
      <div
        className="col-12 "
        id="chart"
        style={{
          backgroundColor: "#fff",
          // border: "1px solid lightgray",
          borderRadius: "10px",
        }}
      >
        <h6 className="p-4 text-white">
          Zendesk Stacked Bar Chart for Last 12 Months
        </h6>
        <Chart options={options} series={series} type="bar" height={450} />
      </div>
    </div>
  );
};

export default ZendeskStackBarChart;
