import React, { useState, ReactNode, useEffect } from "react";
import "./Dashboard.css";
import { BrowserRouter as Router, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import AllyLogo from "../../assets/images/allylogo.png";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

function DashboardTest() {
  const [state, setState] = React.useState({
    run: false,
    steps: [
      {
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
              padding: "20px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              position: "relative",
              fontFamily: "Poppins",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",

                left: "10px",
                right: "10px",
                bottom: "10px",
                borderRadius: "8px",
                background:
                  "linear-gradient(to bottom right, #e9ecef, #ffffff)",
                zIndex: -1,
                fontFamily: "Poppins",
              }}
            ></div>
            <div>
              <i
                className="bx bxs-bot bx-lg"
                style={{
                  color: "#6c757d",
                  marginBottom: "12px",
                }}
              ></i>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginBottom: "12px",
                  color: "#343a40",
                  letterSpacing: "0.5px",
                  fontFamily: "Poppins",
                }}
              ></h2>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0",
                  color: "#6c757d",
                  lineHeight: "1.6",
                  fontFamily: "Poppins",
                  padding: "2px",
                }}
              ></p>
            </div>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">Skip</strong> },
        placement: "right",
        target: "body",
      },
    ],
  });
  const handleClickStart = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      run: true,
    }));
  };
  useEffect(() => {
    const status = sessionStorage.getItem("suggestions");
    console.log(status, "statuxxss");
  }, [sessionStorage.getItem("suggestions")]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
    }

    console.log(type, data);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  let [routeName, setRouteName] = useState("dash");

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    console.log("hello");
  };
  const logoutFunction = () => {
    navigate("/login");
    sessionStorage.clear();
  };
  const getActiveClass = (route) => {
    return location.pathname === route ? "sidebar-link active" : "sidebar-link";
  };
  const navigateTo = (route) => {
    navigate(route);
  };

  const navigation = () => {
    if (routeName == "dash") {
      navigate("/dashboard/homepage");
    } else if (routeName == "chat") {
      navigate("/dashboard/chat");
    } else if (routeName == "profile") {
      navigate("/dashboard/profile");
    } else if (routeName == "settings") {
      navigate("/dashboard/settings");
    } else if (routeName == "notifications") {
      navigate("/dashboard/notifications");
    } else if (routeName == "talktodata") {
      navigate("/dashboard/talktodata");
    }
  };
  const goToRoute = () => {
    navigate("/landingpage");
  };

  return (
    <div className="wrapper">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={state.run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={state.steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <aside id="sidebar">
        {/* className={isExpanded ? 'expand' : ''} */}
        <div className="d-flex bg-clr">
          <button className="toggle-btn" type="button" onClick={toggleSidebar}>
            <img
              src={AllyLogo}
              alt=""
              style={{ width: "55px", height: "15px" }}
            />
            {/* <p
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: "24px",
                marginTop: "0px",
              }}
              onClick={() => {
                navigate("/");
                sessionStorage.clear();
              }}
            >
              ALLY
            </p> */}
          </button>
        </div>

        <ul className="sidebar-nav">
          <li
            className="sidebar-item body"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRouteName("dash");
              navigation();
            }}
          >
            <a
              className="sidebar-link collapsed has-dropdown dashcam"
              data-bs-toggle="collapse"
              data-bs-target="#dashboardDropdown"
              aria-expanded="false"
              aria-controls="dashboardDropdown"
            >
              <i className="fa fa-dashboard"></i>
              <span>Dashboard</span>
            </a>
            <ul
              id="dashboardDropdown"
              className="sidebar-dropdown list-unstyled collapse"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item">
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#dashboardSubDropdown"
                  aria-expanded="false"
                  aria-controls="dashboardSubDropdown"
                >
                  Dashboard
                </a>
              </li>
            </ul>
          </li>
          <li className="sidebar-item mt-3 mb-2">
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown chatApplication"
              data-bs-toggle="collapse"
              data-bs-target="#chatDropdown"
              aria-expanded="false"
              aria-controls="chatDropdown"
              style={{ width: "100%", display: "block" }}
            >
              <i className="fa fa-comments"></i>
              <span>Chats</span>
            </a>
            <ul
              id="chatDropdown"
              className="sidebar-dropdown list-unstyled collapse"
              data-bs-parent="#sidebar"
              style={{ paddingLeft: "0", marginLeft: "0" }}
            >
              <li
                className="sidebar-item"
                onClick={() => {
                  setRouteName("chat");
                  navigation();
                }}
              >
                <a
                  className="sidebar-link collapsed"
                  style={{
                    width: "100%",
                    display: "block",
                    padding: "10px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setRouteName("chat");
                    navigation();
                  }}
                >
                  <i className="fa fa-comments" style={{ color: "white" }}></i>
                  Query Chat
                </a>
              </li>
              <li
                className="sidebar-item"
                onClick={() => {
                  setRouteName("talktodata");
                  navigation();
                }}
              >
                <a
                  onClick={() => {
                    setRouteName("talktodata");
                    navigation();
                  }}
                  className="sidebar-link collapsed"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 15px",
                    cursor: "pointer",
                  }}
                >
                  <i className="fa fa-database" style={{ color: "white" }}></i>
                  Transactional Chat
                </a>
              </li>
            </ul>
          </li>
          <li
            className="sidebar-item mt-4"
            onClick={() => {
              setRouteName("profile");
              navigation();
            }}
          >
            <a
              href="#"
              className="sidebar-link collapsed has-dropdown profileDiv"
              data-bs-toggle="collapse"
              data-bs-target="#profileDropdown"
              aria-expanded="false"
              aria-controls="profileDropdown"
            >
              <i className="fa fa-user"></i>
              <span>Profile</span>
            </a>
            <ul
              id="profileDropdown"
              className="sidebar-dropdown list-unstyled collapse"
              data-bs-parent="#sidebar"
            >
              <li className="sidebar-item" style={{ width: "10px" }}>
                <a
                  href="#"
                  className="sidebar-link collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#profileSubDropdown"
                  aria-expanded="false"
                  aria-controls="profileSubDropdown"
                >
                  Profile
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div className="sidebar-footer" style={{ cursor: "pointer" }}>
          {sessionStorage.getItem("email") === "testAlly@allypro.com" && (
            <a className="sidebar-link" onClick={goToRoute}>
              <i className="fa fa-exchange"></i>
              <span>Logout</span>
            </a>
          )}

          <a className="sidebar-link" onClick={logoutFunction}>
            <i className="fa fa-sign-out"></i>
            <span>Logout</span>
          </a>
        </div>
      </aside>

      <div className="main" style={{ backgroundColor: "#fff" }}>
        <Outlet />
      </div>
    </div>
  );
}

export { DashboardTest };
