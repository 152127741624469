import React from "react";
import "./NewUI.css";
import { useState, useEffect } from "react";
import CustomerTickets from "./CustomerTickets.tsx";
import newchat from "../../assets/images/newchat.png";

const NewUI = () => {
  const [activePersona, setActivePersona] = useState(
    sessionStorage.getItem("userRole")
  );
  const chats = [
    "Tell me something about customer portal?",
    "Tell me different parts of pets we can groom here?",
    "What are different pets we can groom in Gingr?"
  ];
  return (
    <div className="container-fluid p-0 m-0">
      <div className="row p-0 m-0 " id="danger">
        <div className="col-12 col-md-2" id="previouschats">
          <div>
            <div className="askquestiondiv d-flex justify-content-between mt-2">
              <h6 className="chatsname">Chats</h6>
              <button className="responsive-button">Ask Question</button>
            </div>
            <div className="form-group has-search mt-2">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                id="searchInput"
                className="form-control"
                placeholder="Search"
                style={{ outlineStyle: "none", border: "1px solid #ede8e8" }}
              />
            </div>
            <div className="chatlist mt-2">
              <div className="chatlistitem">
                <div className="col-12 d-flex chatlistitemchanges">
                  <div className="col-2">
                    <img
                      src={newchat}
                      alt=""
                      className="chatimage"
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6
                      className=""
                      style={{
                        fontWeight: "200",
                        fontSize: "16px",
                        marginTop: "2px",
                      }}
                    >
                      Tell me something about customer portal ?
                    </h6>
                  </div>
                </div>
                <div className="col-12 col-md-12 d-flex chatlistitemchanges">
                  <div className="col-2">
                    <img
                      src={newchat}
                      alt=""
                      className="chatimage"
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6
                      className=""
                      style={{
                        fontWeight: "200",
                        fontSize: "16px",
                        marginTop: "2px",
                      }}
                    >
                      tell me differnt parts of pets we can groom here ? 
                    </h6>
                  </div>
                </div>
                <div className="col-12 col-md-12 d-flex chatlistitemchanges">
                  <div className="col-2">
                    <img
                      src={newchat}
                      alt=""
                      className="chatimage"
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6
                      className=""
                      style={{
                        fontWeight: "200",
                        fontSize: "16px",
                        marginTop: "2px",
                      }}
                    >
                       what are different pets we can groom in gingr ?
                    </h6>
                  </div>
                </div>
                <div className="col-12 col-md-12 d-flex chatlistitemchanges">
                  <div className="col-2">
                    <img
                      src={newchat}
                      alt=""
                      className="chatimage"
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                  <div className="col-10">
                    <h6
                      className=""
                      style={{
                        fontWeight: "200",
                        fontSize: "16px",
                        marginTop: "2px",
                      }}
                    >
                       what are different pets we can groom in gingr ?
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="trendingdiv">
              <h6 className="trendingname mt-1">Trending Topics</h6>
              <div className="resizable-container">
                <div className="resizable-div">Jira</div>
                <div className="resizable-div">Jira Analytics</div>
                <div className="resizable-div">Tickets</div>
                <div className="resizable-div">Zendesk Queries</div>
                <div className="resizable-div">Customer</div>
                <div className="resizable-div">Customer Portal</div>
                <div className="resizable-div">Analytics</div>
                <div className="resizable-div">Password Generation</div>
              </div>
            </div>
            <div className="productupdatediv mt-2">
              <h6 className="productname">Product Update</h6>
              <h6 className="subheading">Releases :-</h6>

              <div
                className="product-update-section"
                style={{ msOverflowStyle: "none", scrollbarWidth: "none" }}
              >
                <ul className="product-updates">
                  <li className="update-card">Gingr 3.0 | 16624</li>
                  <li className="update-card">
                    Gingr 2.0.0 | Borg Architecture | 15149
                  </li>
                  <li className="update-card">
                    Gingr 1.5.2 | Performance Improvements | 13347
                  </li>
                  <li className="update-card">
                    Gingr 1.3.1 | Bug Fixes | 12134
                  </li>
                  <li className="update-card">
                    Gingr 1.0.0 | Initial Release | 10000
                  </li>
                </ul>
                <h6 className="morereleases" style={{ cursor: "pointer" }}>
                  Check for More Releases :-
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-8" id="chattingdiv">
          {/* CHATTING SCREEN */}
        </div>
        <div className="col-12 col-md-2" id="ticketsdiv">
          {activePersona === "Ally-User" && (
            <>
              <CustomerTickets />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewUI;
