import React, { useState, useRef, useEffect } from "react";

import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import userlogo from "../../assets/images/prof.png";
import gingrally from "../../assets/images/gingrally.png";
import TypeWriter from "../Chatpage/TypeWriter.tsx";
import Intendation from "../Chatpage/Intendation.tsx";
import SqlQueryViewer from "../SqlQueryViewer/SqlQueryViewer.js";
import { Chat } from "../Chatpage/Chat.tsx";

interface IMessage {
  forEach(arg0: (chat: any) => void): unknown;
  id: string;
  name: string;
  type: string;
  output: React.ReactNode;
  createdAt: string;
  relevantLinks: string[];
  followUpQuestions: string[];
}
export const Talktodata = () => {
  const [loading, setLoading] = useState(false);
  const [chatScreen, setChatScreen] = useState(false);
  let [showingSuggestions, setShowingSuggestions] = useState(false);

  const [showChats, setShowChats] = useState(false);
  const [chats, setChats] = useState<IMessage[]>([]);
  const currentTime = moment().tz("Asia/Kolkata");
  let [chatIds, setChatId] = useState("");
  const [apiData, setApiData] = useState<any>(null);

  const [apiData1, setApiData1] = useState<any>(null);
  const [apiData2, setApiData2] = useState<any>(null);
  let [inputValue, setInputValue] = useState("");

  let [inputData, setInputData] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);

  const [showLoader, setShowLoader] = useState(false);

  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(-1);
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredChats, setFilteredChats] = useState([]);

  const [dataframes, setDataFrames] = useState([]);

  const hours = currentTime.hour();
  let greeting;
  if (hours < 12) {
    greeting = "Good morning";
  } else if (hours < 17) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }
  const [uniqueChatsLast15Days, setUniqueChatsLast15Days] = useState({});

  const handleSendMessage = async () => {
    console.log(chatIds, "Chat Id is");
    console.log(
      `${process.env.REACT_APP_LOCALHOST_API_LINK}/sql_query`,
      "Sql query"
    );

    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    setDataFrames([]);

    // setShowDiv(!showDiv);

    const content = inputValue.trim();
    if (content) {
      setInputData(content);
      const userMessage: IMessage = {
        id: uuidv4(),
        name: "user",
        type: "user_message",
        output: content,
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const fetchingMessage: IMessage = {
        id: uuidv4(),
        name: "me",
        type: "bot_message",
        output: "Fetching Data..",
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, fetchingMessage]);

      // console.log(content, "Input Data");
      setInputValue(""); // Clear input field
      setShowLoader(true);

      try {
        const token = sessionStorage.getItem("token");
        console.log(chatIds, "Chat id i received is in handle message");
        const link =
          sessionStorage.getItem("userRole") === "Ally-User"
            ? "query-file"
            : "support-query-file";

        const response = await fetch(
          `${process.env.REACT_APP_LOCALHOST_API_LINK}/sql_query`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: content,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        if (response.ok) {
          setShowingSuggestions(true);
        }

        const data = await response.json();
        console.log(data, "Data Response");
        const res = data.result;
        const sqlquery = data.metadata;
        console.log(data.metadata, "dta metadata");
        const frames = data.data_frame;
        setDataFrames(frames);

        setApiData1(data.metadata);
        console.log();

        const relevantLinks = data.relevantLinks || [];
        const followupQuestions = data.follow_up_questions || [];

        const botMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: res,
          createdAt: new Date().toISOString(),
          isNew: true,
          relevantLinks: relevantLinks,
          followupQuestions: followupQuestions,
        };

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === fetchingMessage.id ? botMessage : msg
          )
        );
        setCurrentMessageIndex(messages.length - 1);
      } catch (error) {
        console.error("Error sending message:", error);

        const errorMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: "There was an error. Can you please retry?",
          createdAt: new Date().toISOString(),
        };

        setMessages((prevMessages) => [...prevMessages, errorMessage]);
        setInputValue("");
      } finally {
        setShowLoader(false);
      }
    }
  };

  const chatsToRender = searchTerm ? filteredChats : uniqueChatsLast15Days;
  const sortedChats = Object.entries(uniqueChatsLast15Days)
    .sort((a, b) => new Date(b[1].createdAt) - new Date(a[1].createdAt))
    .slice(0, 2);

  const [showSubmit, setShowSubmit] = useState(false);

  const handleInput = (e) => {
    if (e.target.value.length > 0) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  };

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (!hasSentInitialMessage) {
      setHasSentInitialMessage(true);
      if (textareaRef.current) {
        textareaRef.current.focus();
        setShowSubmit(true);
      }
    }
  }, [hasSentInitialMessage]);
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  const refreshNames1 = () => {
    setCurrentSuggestions(getRandomSuggestions(suggestionsare, 3));
  };
  const [suggestionsare, setsuggestionare] = useState([]);

  const getRandomSuggestions = (arr, num) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };
  const [lookup, setLookup] = useState({});

  const setinputmessage = (nameId) => {
    const question = lookup[nameId];
    if (question) {
      setInputValue(question);
      setShowSubmit(true);
    }
  };

  const performAction = () => {
    try {
      if (suggestion === "Hide Suggestions") {
        setHideSuggestions(true);
        console.log("true");
      }
    } catch {
      console.log("error");
    }
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleToggle1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const listChats = async () => {
    setMessages([]);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    setShowChats(!showChats);
  };
  const listChats1 = async () => {
    setMessages([]);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
  };
  const handleClickChatEntry = async (chatId) => {
    console.log(chatId, "Chat Id is");
    setApiData1([]);
    setApiData2([]);
    setInputValue("");
    console.log(chats, "Chats are this");
    setShowLoader(true);
    setChatScreen(true);
    setChatId((chatIds = chatId));
    console.log(chatIds, "Chat Ids are this");
    console.log(chatId, "Current Chatting ID");
    const chatHistory = chats[chatId];
    console.log(chatHistory, "Chat History History");
    const updatedMessages = Array.isArray(chatHistory)
      ? chatHistory
      : [chatHistory];
    console.log(updatedMessages, "Updated Messages");
    const filteredUserChats = updatedMessages.filter(
      (data) => data.user === sessionStorage.getItem("email")
    );
    const userFilteredOne = filteredUserChats.reverse();
    console.log(userFilteredOne, "Filtered One");

    const processedMessages = updatedMessages.map((msg) => {
      console.log(msg, "message");
      if (msg.user === sessionStorage.getItem("userRole")) {
        console.warn("inside this ");

        return {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: msg.response,
          createdAt: new Date().toISOString(),
          isNew: false,
        };
      } else {
        return {
          id: uuidv4(),
          name: "user",
          type: "user_message",
          output: msg.response,
          createdAt: new Date().toISOString(),
          isNew: false,
        };
      }
    });
    setMessages(processedMessages);
    console.log(messages, "Messages");
    const token = sessionStorage.getItem("token");

    setShowLoader(true);
    const response2 = await fetch(
      `${process.env.REACT_APP_SERVER_API_LINK}/jira-query`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          question: userFilteredOne[0].response,
        }),
      }
    );
    const data2 = await response2.json();
    console.warn(messages, "Messchin");
    console.log(data2, "Response 2");
    if (data2) {
      setApiData1(data2);
      setShowLoader(false);
    }
    setShowLoader(true);
    const response3 = await fetch(
      `${process.env.REACT_APP_SERVER_API_LINK}/zendesk-query`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_query: userFilteredOne[0].response,
        }),
      }
    );
    const data3 = await response3.json();
    console.warn(messages, "Messchin");
    console.log(data3, "Response 2");
    if (data3) {
      setApiData2(data3);
      setShowLoader(false);
    }
  };
  const getFormattedTime = (dateString) => {
    const serverDate = new Date(dateString);

    // Convert server date (assumed to be in UTC) to local time
    const localDate = new Date(
      serverDate.getTime() - serverDate.getTimezoneOffset() * 60000
    );

    // console.log("Local Date:", localDate);

    return formatDistanceToNow(localDate, { addSuffix: true });
  };
  const chatScreenShow = async () => {
    setChatScreen(!chatScreen);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    try {
      const token = sessionStorage.getItem("token");
      console.log(token, "Token Received!!");
      console.log(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`
      );

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.chat_id, "Latest Chat Id");
      setChatId((chatIds = data.chat_id));
      console.log(chatIds, "ChatIds inside the box");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  const chatScreenShow1 = async () => {
    setShowChats(!showChats);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    try {
      const token = sessionStorage.getItem("token");
      console.log(token, "Token Received!!");
      console.log(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`
      );

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.chat_id, "Latest Chat Id");
      setChatId((chatIds = data.chat_id));
      console.log(chatIds, "ChatIds inside the box");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  const divRef = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);
  const checkForScrollbar = () => {
    const div = divRef.current;
    if (div) {
      const isAtBottom =
        div.scrollHeight - div.scrollTop <= div.clientHeight + 1;
      setShowButton(!isAtBottom && div.scrollHeight > div.clientHeight);
    }
  };

  // Scroll event handler
  const handleScroll = () => {
    checkForScrollbar();
  };

  const hideButton = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
      setShowButton(false);
    }
  };

  const scrollToBottom = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
      setShowButton(false);
    }
  };

  // Set up event listeners and observers
  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
      checkForScrollbar(); // Initial check

      // Optional: Observe changes in content if dynamically updated
      const observer = new MutationObserver(checkForScrollbar);
      observer.observe(div, { childList: true, subtree: true });

      return () => {
        div.removeEventListener("scroll", handleScroll);
        observer.disconnect();
      };
    }
  }, []);

  // const headers = Object.keys(dataframes[0]);

  const renderMessage = (message, index) => {
    const isCurrentMessage = message === messages[messages.length - 1];
    // console.log(message.type,"Message.type")
    const headers = dataframes.length > 0 ? Object.keys(dataframes[0]) : [];
    return (
      <li
        key={message.id}
        className={message.type === "user_message" ? "repaly" : "sender"}
      >
        <span>
          <br />
          <p>
            {message.type === "user_message" ? (
              <>
                <span style={{ display: "flex", alignItems: "flex-start" }}>
                  <img
                    src={message.type === "user_message" ? userlogo : gingrally}
                    alt=""
                    width={message.type === "user_message" ? 30 : 70}
                    style={{ marginRight: "10px" }} // Adjust margin as needed
                  />
                  <span
                    style={{ flex: 1, textAlign: "justify", marginTop: "4px" }}
                  >
                    {message.output}
                  </span>
                </span>
              </>
            ) : (
              <>
                <span>
                  <img
                    src={message.type === "user_message" ? userlogo : gingrally}
                    alt=""
                    width={message.type === "user_message" ? 30 : 70}
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                  <span style={{ marginTop: "4px", paddingLeft: "35px" }}>
                    {isCurrentMessage ? (
                      // <TypeWriter text={message.output} speed={20} />
                      <>
                        {" "}

                        <TypeWriter text={message.output} speed={20} />
                        {dataframes.length > 0 ? (
                          <table
                            style={{
                              width: "100%",
                              borderCollapse: "collapse",
                              margin: "20px 0",
                              overflowX: "auto", // Allows horizontal scrolling on small screens
                            }}
                          >
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#333",
                                }}
                              >
                                {headers.map((header, index) => (
                                  <th
                                    key={index}
                                    style={{
                                      padding: "12px",
                                      border: "1px solid #ddd",
                                      textAlign: "left",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {dataframes.length > 0 ? (
                                dataframes.map((item, rowIndex) => (
                                  <tr
                                    key={rowIndex}
                                    style={{
                                      backgroundColor:
                                        rowIndex % 2 === 0
                                          ? "#f9f9f9"
                                          : "white",
                                    }}
                                  >
                                    {headers.map((header, colIndex) => (
                                      <td
                                        key={colIndex}
                                        style={{
                                          padding: "12px",
                                          border: "1px solid #ddd",
                                          textAlign: "left",
                                        }}
                                      >
                                        {item[header]}
                                      </td>
                                    ))}
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={headers.length}
                                    style={{
                                      padding: "12px",
                                      textAlign: "center",
                                      border: "1px solid #ddd",
                                    }}
                                  >
                                    No data available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <p></p>
                        )}
                      </>
                    ) : (
                      <Intendation text={message.output} />
                    )}
                  </span>
                </span>
              </>
            )}
          </p>
          <br />
        </span>
      </li>
    );
  };
  return (
    <div className="container-fluid p-0 m-0">
      <div className=" row p-0 m-0">
        {/* <div className="col-md-4 p-0 m-0">
        <Chat fullWidth={true} />

        </div> */}
        <div className="col-md-2">
          <Chat fullWidth={true} />
        </div>
        <div
          className="col-12 col-md-10 p-0"
          style={{ backgroundColor: "white" }}
        >
          <div className="container-fluid aichatdiv">
            {loading ? (
              <>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100vh" }}
                >
                  <div className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  {!chatScreen && (
                    <>
                      <>
                        <div className="col-md-12">
                          <div className="col-12 d-flex justify-content-end">
                            <div
                              className="button-container"
                              onClick={listChats1}
                            >
                              <button className="start-chat-button">
                                <i
                                  className="bx bx-plus-circle bx-xs"
                                  style={{ marginRight: "5px" }}
                                ></i>
                                <span
                                  className="button-text"
                                  style={{ marginTop: "2px" }}
                                >
                                  Start new chat
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div id="Chatpage">
                          <section className="message-area">
                            <div className="container-fluid p-0 m-0">
                              <div className="row">
                                <div className="col-md-12 m-0">
                                  <div className="chat-area p-1">
                                    <div className="chatbox">
                                      <div className="modal-dialog-scrollable">
                                        <div className="modal-content">
                                          <div className="">
                                            <div className="row">
                                              <div className="col-8">
                                                <div className="d-flex align-items-center">
                                                  <span className="chat-icon">
                                                    <img
                                                      className="img-fluid"
                                                      src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                                      alt="image title"
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-4">
                                                <ul className="moreoption"></ul>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="modal-body"
                                            ref={divRef}
                                            style={{
                                              overflowY: "auto",
                                              height: "100vh",
                                            }}
                                          >
                                            <div className="msg-body">
                                              <ul
                                                className="messages"
                                                style={{ paddingLeft: "0" }}
                                              >
                                                {messages.map(
                                                  (message, index) =>
                                                    renderMessage(
                                                      message,
                                                      index
                                                    )
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="send-box">
                                            <form
                                              onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSendMessage();
                                              }}
                                            >
                                              <input
                                                type="text"
                                                className="form-control"
                                                aria-label="message…"
                                                placeholder="Write message…"
                                                onChange={(e) =>
                                                  setInputValue(
                                                    (inputValue =
                                                      e.target.value)
                                                  )
                                                }
                                                value={inputValue}
                                              />
                                              <button
                                                type="button"
                                                onClick={handleSendMessage}
                                              >
                                                <i
                                                  className="fa fa-paper-plane"
                                                  aria-hidden="true"
                                                />{" "}
                                              </button>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </>
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          {/* <AIChat /> */}
        </div>
        {/* <div className="col-md-3">
        <SqlQueryViewer apiData1={apiData1}/>
        </div> */}

        {/* <div className="col-md-4">
            <SqlQueryViewer/>
        </div> */}
      </div>
    </div>
  );
};
