import React, { useState } from "react";
import Chart from "react-apexcharts";
// import "./ChartComponent.css";
import "./HeatMap.css";

const HeatMap: React.FC = () => {
  const statuses1 = [
    "Open",
    "In Progress",
    "Resolved",
    "Closed",
    "In Review",
    "Draft",
  ];

  const generateData = () => {
    const dates = [
      "2024-07-21",
      "2024-07-22",
      "2024-07-23",
      "2024-07-24",
      "2024-07-25",
      "2024-07-26",
      "2024-07-27",
      "2024-07-28",
      "2024-07-29",
      "2024-07-30",
      "2024-07-31",
      "2024-08-01",
      "2024-08-02",
      "2024-08-03",
    ];

    const statuses = ["Blocker", "Critical", "Major", "Trivial", "Minor"];

    const data = statuses.map((status) => {
      return {
        name: status,
        data: dates.map((date) => {
          return {
            x: date,
            y: Math.floor(Math.random() * 26),
          };
        }),
      };
    });

    return data;
  };

  const [selectedStatus, setSelectedStatus] = useState(statuses1[0]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const getColorRange = () => {
    switch (selectedStatus) {
      case "Open":
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
      case "In Progress":
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
      case "Resolved":
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
      case "Closed":
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
      case "In Review":
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
      case "Draft":
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
      default:
        return [
          "#E3F2FD",
          "#BBDEFB",
          "#90CAF9",
          "#64B5F6",
          "#42A5F5",
          "#1E88E5",
          "#1976D2",
        ];
    }
  };

  const colorRange = getColorRange().map((color, index) => ({
    from: index * 4,
    to: (index + 1) * 4,
    color,
  }));

  const series = generateData();
  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.6,
        colorScale: {
          ranges: colorRange,
        },
        distributed: true,
        enableShades: false,
        borderColor: "transparent", // Ensure no borders between cells
        radius: 0, // Remove rounded corners
      },
    },
    stroke: {
      show: false, // Hide any stroke around the heatmap cells
    },
    grid: {
      show: false, // Hide grid lines
      borderColor: "transparent", // Remove grid borders
      position: "back", // Ensure grid lines are in the background
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
    },
    yaxis: {
      axisBorder: {
        show: false, // Hide y-axis border
      },
      axisTicks: {
        show: false, // Hide y-axis ticks
      },
    },
    title: {
      // text: "Zendesk Tickets HeatMap",
      align: "center",
      style: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    legend: {
      show: true,
      position: "right",
      offsetY: 40,

      height: 230,
      labels: {
        useSeriesColors: false,
        colors: "#808080", // Set legend label color to gray
      },
    },
  };

  return (
    <div className="cards">
      <div className="col-md-12 card-bodys">
        <h6 style={{ color: "gray", marginLeft: "11px", textAlign: "center" }}>
          Global Ticket Traffic
        </h6>
        <select
          name="status-select"
          id="status-select"
          className="mt-3 mr-3 custom-select"
          value={selectedStatus}
          onChange={handleChange}
          style={{ marginLeft: "10px" }}
        >
          {statuses1.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>

        <Chart options={options} series={series} type="heatmap" height={350} />
      </div>
    </div>
  );
};

export default HeatMap;

// import React, { useState } from "react";
// import Chart from "react-apexcharts";
// import "./HeatMap.css";

// const HeatMap: React.FC = () => {
//   const statuses1 = [
//     "Open",
//     "In Progress",
//     "Resolved",
//     "Closed",
//     "In Review",
//     "Draft",
//   ];

//   const generateData = () => {
//     const dates = [
//       "2024-07-21",
//       "2024-07-22",
//       "2024-07-23",
//       "2024-07-24",
//       "2024-07-25",
//       "2024-07-26",
//       "2024-07-27",
//       "2024-07-28",
//       "2024-07-29",
//       "2024-07-30",
//       "2024-07-31",
//       "2024-08-01",
//       "2024-08-02",
//       "2024-08-03",
//     ];

//     const statuses = ["Blocker", "Critical", "Major", "Trivial", "Minor"];

//     const data = statuses.map((status) => {
//       return {
//         name: status,
//         data: dates.map((date) => {
//           return {
//             x: date,
//             y: Math.floor(Math.random() * 26),
//           };
//         }),
//       };
//     });

//     return data;
//   };

//   const [selectedStatus, setSelectedStatus] = useState(statuses1[0]);

//   const handleTabChange = (status: string) => {
//     setSelectedStatus(status);
//   };

//   const getColorRange = () => {
//     switch (selectedStatus) {
//       case "Open":
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//       case "In Progress":
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//       case "Resolved":
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//       case "Closed":
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//       case "In Review":
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//       case "Draft":
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//       default:
//         return [
//           "#fdfdfe",
//           "#e6e6ee",
//           "#dbdbe6",
//           "#c4c4d5",
//           "#adadc5",
//           "#9696b5",
//           "#8a8bad",
//         ];
//     }
//   };

//   const colorRange = getColorRange().map((color, index) => ({
//     from: index * 4,
//     to: (index + 1) * 4,
//     color,
//   }));

//   const series = generateData();
//   const options = {
//     chart: {
//       type: "heatmap",
//       toolbar: {
//         show: false,
//       },
//     },
//     plotOptions: {
//       heatmap: {
//         shadeIntensity: 0.6,
//         colorScale: {
//           ranges: colorRange,
//         },
//         distributed: true,
//         enableShades: false,
//         borderColor: "transparent", // Ensure no borders between cells
//         radius: 0, // Remove rounded corners
//       },
//     },
//     stroke: {
//       show: false, // Hide any stroke around the heatmap cells
//     },
//     grid: {
//       show: false, // Hide grid lines
//       borderColor: "transparent", // Remove grid borders
//       position: "back", // Ensure grid lines are in the background
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     xaxis: {
//       type: "category",
//       labels: {
//         rotate: -45,
//       },
//       axisBorder: {
//         show: false, // Hide x-axis border
//       },
//       axisTicks: {
//         show: false, // Hide x-axis ticks
//       },
//     },
//     yaxis: {
//       axisBorder: {
//         show: false, // Hide y-axis border
//       },
//       axisTicks: {
//         show: false, // Hide y-axis ticks
//       },
//     },
//     title: {
//       align: "center",
//       style: {
//         fontSize: "24px",
//         fontWeight: "bold",
//         color: "#333",
//       },
//     },
//     legend: {
//       show: true,
//       position: "right",
//       offsetY: 40,
//       height: 230,
//       labels: {
//         useSeriesColors: false,
//         colors: '#808080', // Set legend label color to gray
//       },
//     },
//   };

//   return (
//     <div className="cards">
//       <div className="col-md-12 card-bodys">
//         <h6 style={{ color: "gray", marginLeft: '11px', textAlign: "center" }}>
//           Global Ticket Traffic
//         </h6>

//         <div className="tab-container">
//           {statuses1.map((status) => (
//             <div
//               key={status}
//               className={`tab ${status === selectedStatus ? 'active' : ''}`}
//               onClick={() => handleTabChange(status)}
//               style={{ cursor: 'pointer', padding: '5px', margin: '16px', textAlign: 'center', borderRadius: '5px',fontSize:'14px',fontWeight:"600",color:'gray' }}
//             >
//               {status}
//             </div>
//           ))}
//         </div>

//         <Chart options={options} series={series} type="heatmap" height={350} />
//       </div>
//     </div>
//   );
// };

// export default HeatMap;
