import React from "react";

const Intendation = ({ text }: { text: string }) => {
  const lines = text.split("\n");

  return (
    <div>
      {lines.map((line, index) => (
        <div key={index} style={{ marginLeft: `${line.startsWith('>') ? 20 : 0}px` }}>
          {line}
        </div>
      ))}
    </div>
  );
};

export default Intendation;
