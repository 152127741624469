import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

const getRandomData = () => {
  const data = [];
  for (let i = 0; i < 12; i++) {
    data.push(Math.floor(Math.random() * (2000 - 100 + 1)) + 100);
  }
  return data;
};

const ZendeskBarChart = () => {
    const months = [
        'Jun 2024', 'Jul 2024', 'Aug 2024', 'Sep 2024', 'Oct 2024', 'Nov 2024',
        'Dec 2023', 'Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 'May 2023'
      ];
  const currentMonth = new Date().getMonth(); // Get the current month (0-indexed)
  const currentYear = new Date().getFullYear(); // Get the current year

  const xLabels = [];
  let monthIndex = (currentMonth - 1 + 12) % 12; // Start from the previous month
  let year = currentYear;

  for (let i = 0; i < 12; i++) {
    xLabels.push(`${months[monthIndex]} ${year}`); // Combine month and year
    monthIndex = (monthIndex - 1 + 12) % 12; // Move to the previous month
    if (monthIndex === 11) {
      year--; // Decrement year if we loop back to December
    }
  }

  xLabels.reverse();

  const series = [
    {
      name: "Tickets",
      data: getRandomData(),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      // Set background color to white
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // Show data labels on top of bars
        },
      },
    },
    xaxis: {
      categories: months, // Use the combined labels for x-axis
    },
    colors: ["#4ad9ef"], // Set the color of bars to blue
  };

  return (
    <div className="row">
      <div className="col-12 d-flex">
        <div
          className="col-10 mr-5"
          id="chart"
          style={{
            backgroundColor: "white",
            padding: "5px",
            borderRadius:'10px'
          }}
        >
          <h6 className="p-4">Zendesk Tickets by Months (Last 12 Months)</h6>
          <Chart options={options} series={series} type="bar" height={450} />
        </div>

        <div
          className="col-2 mt-5"
          style={{
            backgroundColor: "white",
            borderRadius:'10px'
          }}
        >
          <div className="p-1">
            <h6 style={{ marginLeft: "5px", marginTop: "15px" }}>
              Zendesk Tickets Stats (Today)
            </h6>
            <hr style={{ color: "gray" }} />
          </div>
          <div>
            <h2 style={{ marginLeft: "10px",fontWeight:'bold' }}>13</h2>
            <p style={{ marginLeft: "10px" }}>New</p>
            <hr style={{ color: "gray" }} />
          </div>
          <div>
            <h2 style={{ marginLeft: "10px",fontWeight:'bold' }}>8</h2>
            <p style={{ marginLeft: "10px" }}>Open</p>
            <hr style={{ color: "gray" }} />
          </div>
          <div>
            <h2 style={{ marginLeft: "10px",fontWeight:'bold' }}>1</h2>
            <p style={{ marginLeft: "10px" }}>Resolved</p>
            <hr style={{ color: "gray" }} />
          </div>
          <div>
            <h2 style={{ marginLeft: "10px",fontWeight:'bold' }}>31</h2>
            <p style={{ marginLeft: "10px" }}>Closed</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZendeskBarChart;
