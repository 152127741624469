import React, { useState } from "react";
import { FaLightbulb, FaQuestionCircle, FaMagic, FaStar } from "react-icons/fa";
import './Suggestions.css'; // Import the CSS file

const SuggestionQuestion = ({ questions, onSelect }) => {
  const icons = [FaLightbulb, FaQuestionCircle, FaMagic, FaStar]; // Array of icons
  console.log(questions,"Questions")

  return (
    <div className="suggest-container">
      {questions.map((question, index) => {
        const Icon = icons[index % icons.length]; // Cycle through icons
        return (
          <div
            key={index}
            className="suggestion-item"
            onClick={() => onSelect(question)}
          >
            {/* <Icon className="icon" /> */}
            <span style={{fontSize:"26px",color:"gray",fontWeight:"200"}}>&#8226;</span>
            <span style={{width:"20px",height:'auto',display:"inline-block"}}></span>
            <span className="suggestion-text1">{question}</span>
          </div>
        );
      })}
    </div>
  );
};

export default SuggestionQuestion;
