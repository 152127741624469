import React, { useState, useRef, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment-timezone";

import "./Chat.css";
import "./AIChat.css";
import Question from "../../assets/images/latest/chat.png";
import Mark from "../../assets/images/latest/question-mark.png";
import CheckMark from "../../assets/images/latest/checkGreen.png";
import Loader from "../../assets/images/LoaderLoading.gif";
import Link from "../../assets/images/link.png";
import ExLink from "../../assets/images/link2.png";
import { useLocation } from "react-router-dom";

import Cancel from "../../assets/images/latest/canc.png";
import WrongSign from "../../assets/images/latest/sign.png";
import Leave from "../../assets/images/latest/leave.png";
import Chatting from "../../assets/images/lvchat.png";
import Profile from "../../assets/images/latest/profile-user.png";
import ThreeDots from "../../assets/images/latest/three-dots.png";
import { Customerpersona } from "../Personas/Customerpersona";
import { T1support } from "../Personas/T1support";
import { T2support } from "../Personas/T2support";
import userlogo from "../../assets/images/prof.png";
import gingrally from "../../assets/images/gingrally.png";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { Modal, Button, Form, Alert } from "react-bootstrap";

import { Professionalsupport } from "../Personas/Professionalsupport";
import { useNavigate } from "react-router-dom";
import TypeWriter from "./TypeWriter.tsx";
import { Chats } from "./Chats.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistanceToNow } from "date-fns";

import {
  faUser,
  faIdBadge,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Indentation from "./Intendation.tsx";
import { AIChat } from "./AIChat.tsx";
import Activities from "../Activities/Activity.tsx";
import SuggestionQuestion from "./SuggestionQuestion.tsx";

interface IMessage {
  forEach(arg0: (chat: any) => void): unknown;
  id: string;
  name: string;
  type: string;
  output: React.ReactNode;
  createdAt: string;
  relevantLinks: string[];
  followUpQuestions: string[];
}

function Chat({ fullWidth }) {
  const [user, setUser] = useState("Hello");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [chatIds, setChatId] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [suggestion, setSuggestion] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [showChats, setShowChats] = useState(false);
  const [chats, setChats] = useState<IMessage[]>([]);
  const [uniqueChatsLast15Days, setUniqueChatsLast15Days] = useState({});
  const [filteredChats, setFilteredChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatScreen, setChatScreen] = useState(false);
  let [inputValue, setInputValue] = useState("");
  let [inputData, setInputData] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [apiData, setApiData] = useState<any>(null);
  const [apiData1, setApiData1] = useState<any>(null);
  const [apiData2, setApiData2] = useState<any>(null);
  let [currentChatId, setCurrentChatId] = useState("");
  const [recentLoader, setRecentLoader] = useState(false);
  const currentTime = moment().tz("Asia/Kolkata");
  let [showingSuggestions, setShowingSuggestions] = useState(false);
  const location = useLocation();
  const hours = currentTime.hour();
  let greeting;
  if (hours < 12) {
    greeting = "Good morning";
  } else if (hours < 17) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }
  const divRef = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);
  const checkForScrollbar = () => {
    const div = divRef.current;
    if (div) {
      const isAtBottom =
        div.scrollHeight - div.scrollTop <= div.clientHeight + 1;
      setShowButton(!isAtBottom && div.scrollHeight > div.clientHeight);
    }
  };

  // Scroll event handler
  const handleScroll = () => {
    checkForScrollbar();
  };

  const hideButton = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
      setShowButton(false);
    }
  };

  const scrollToBottom = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
      setShowButton(false);
    }
  };

  // Set up event listeners and observers
  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
      checkForScrollbar(); // Initial check

      // Optional: Observe changes in content if dynamically updated
      const observer = new MutationObserver(checkForScrollbar);
      observer.observe(div, { childList: true, subtree: true });

      return () => {
        div.removeEventListener("scroll", handleScroll);
        observer.disconnect();
      };
    }
  }, []);
  // const checkForScrollbar = () => {
  //   const div = divRef.current;
  //   if (div) {
  //     const isAtBottom = div.scrollHeight - div.scrollTop === div.clientHeight;
  //     const scrollbarActive = !isAtBottom && div.scrollHeight > div.clientHeight;
  //     setHasScrollbar(scrollbarActive);
  //     setShowButton(scrollbarActive); // Show button if scrollbar is active
  //   }
  // };
  // useEffect(() => {
  //   checkForScrollbar(); // Initial check

  //   const div = divRef.current;
  //   if (div) {
  //     div.addEventListener("scroll", checkForScrollbar);
  //   }

  //   window.addEventListener("resize", checkForScrollbar);

  //   // Cleanup on unmount
  //   return () => {
  //     if (div) {
  //       div.removeEventListener("scroll", checkForScrollbar);
  //     }
  //     window.removeEventListener("resize", checkForScrollbar);
  //   };
  // }, []);
  // const hideButton = () => {
  //   // Scroll to the bottom of the div
  //   const div = divRef.current;
  //   if (div) {
  //     div.scrollTop = div.scrollHeight; // Scroll to bottom
  //   }
  //   setShowButton(false); // Hide button on function call
  // };
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleToggle1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const names = [
    {
      id: "Staff Scheduling",
      question:
        "How can I assign shifts to my staff members in the Gingr application?",
    },
    {
      id: "Invoicing Refunds",
      question:
        "What is the process for issuing refunds to customers through the Gingr invoicing system?",
    },
    {
      id: "Jira Analytics",
      question:
        "How can I access and interpret analytics data in Jira within the Gingr application?",
    },
    {
      id: "Pricing Rules",
      question: "How do I set up custom pricing rules for services in Gingr?",
    },
    {
      id: "User Permissions",
      question:
        "How can I manage user permissions and access levels for different staff members in Gingr?",
    },
    {
      id: "Service Reservations",
      question:
        "What is the procedure for making and managing service reservations in Gingr?",
    },
    {
      id: "Email System",
      question:
        "How can I customize and automate email notifications for customers using the Gingr email system?",
    },
    {
      id: "Account Billing",
      question:
        "What options are available for managing account billing and payment methods in Gingr?",
    },
    {
      id: "Group Permissions",
      question:
        "How do I set up group permissions to control access for specific user groups in Gingr?",
    },
    {
      id: "Invoice Adjustments",
      question:
        "How can I make adjustments to invoices for services rendered in Gingr?",
    },
    {
      id: "Service Configuration",
      question:
        "What steps are involved in configuring new services or modifying existing services in Gingr?",
    },
    {
      id: "User Log-in",
      question:
        "How can users log in to their accounts securely and efficiently in the Gingr application?",
    },
  ];

  const getRandomNames = (arr, num) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  const [currentNames, setCurrentNames] = useState(
    getRandomNames(
      names.map((name) => name.id),
      3
    )
  );
  const [showSubmit, setShowSubmit] = useState(false);

  const refreshNames = () => {
    setCurrentNames(
      getRandomNames(
        names.map((name) => name.id),
        3
      )
    );
  };

  const [suggestionsare, setsuggestionare] = useState([]);
  const [currentSuggestions, setCurrentSuggestions] = useState([]);
  const [recentActs, setrecentActs] = useState([]);

  const [lookup, setLookup] = useState({});
  useEffect(() => {
    getSuggestion();
  }, []);

  const getSuggestion = async () => {
    try {
      const fetchsuggestions = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/Initial-response/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      const result = await fetchsuggestions.json();
      setsuggestionare(result.InitialQuestions);
      console.log(result, "suggestions result");
      const lookupTable = result.InitialQuestions.reduce(
        (acc, { key, question }) => {
          acc[key] = question;
          return acc;
        },
        {}
      );
      setLookup(lookupTable);

      setCurrentSuggestions(getRandomSuggestions(result.InitialQuestions, 3));
    } catch (error) {
      console.error("Error while fetching suggestions!!", error);
    }
  };

  const getRandomSuggestions = (arr, num) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  // const setinputmessage = (nameId) => {
  //   const question = lookup[nameId];
  //   if (question) {
  //     setInputValue(question);
  //     setShowSubmit(true);
  //   }
  // };
  const setinputmessage = (nameId) => {
    const question = lookup[nameId];
    if (question) {
      setInputValue(question);
      setShowSubmit(true);
    }
  };
  const refreshNames1 = () => {
    setCurrentSuggestions(getRandomSuggestions(suggestionsare, 3));
  };

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (!hasSentInitialMessage) {
      setHasSentInitialMessage(true);
      if (textareaRef.current) {
        textareaRef.current.focus();
        setShowSubmit(true);
      }
    }
  }, [hasSentInitialMessage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.history.replaceState({}, "");
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (location.state && location.state.inputValue && !hasSentInitialMessage) {
      setInputValue(location.state.inputValue);
      if (textareaRef.current) {
        textareaRef.current.focus();
        setShowSubmit(true);
      }
      const intervalId = setInterval(() => {}, 10000);
      return () => clearInterval(intervalId);
    }
  }, [location.state, hasSentInitialMessage]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (!term) {
      setFilteredChats([]);
      return;
    }

    const filterChats = (chats) => {
      return Object.entries(chats)
        .filter(([chatId, chat]) =>
          chat.firstWords.toLowerCase().includes(term)
        )
        .reduce((acc, [chatId, chat]) => ({ ...acc, [chatId]: chat }), {});
    };

    const filtered = filterChats(uniqueChatsLast15Days);
    setFilteredChats(filtered);
  };

  const listChats = async () => {
    setMessages([]);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    setShowChats(!showChats);
  };
  const listChats1 = async () => {
    setMessages([]);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    setChatScreen(!chatScreen);
  };

  const performAction = () => {
    try {
      if (suggestion === "Hide Suggestions") {
        setHideSuggestions(true);
        console.log("true");
      }
    } catch {
      console.log("error");
    }
  };

  const handleInput = (e) => {
    if (e.target.value.length > 0) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  };
  useEffect(() => {
    getChats();
  }, []);
  const getChats = async () => {
    // setLoading(true);
    setRecentLoader(true);
    try {
      const token = sessionStorage.getItem("token");
      const userEmail = sessionStorage.getItem("email");

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/chat-history/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data.MetaData");
      setChats(data.MetaData);
      const currentDate = new Date();
      const uniqueChatsLast15Days = {};

      Object.entries(data.MetaData).forEach(([chatId, chats]) => {
        chats.forEach((chat) => {
          const chatDate = chat.resp_time.split("T")[0];
          const user = chat.user;
          const words = chat.response.split(" ");
          const firstWords =
            user === userEmail ? words.slice(0, 14).join(" ") : null;

          const diffInDays = Math.floor(
            (currentDate - new Date(chatDate)) / (1000 * 60 * 60 * 24)
          );
          if (diffInDays <= 15 && firstWords && words.length >= 0) {
            uniqueChatsLast15Days[chatId] = {
              firstWords,
              createdAt: chat.resp_time,
            };
          }
        });
      });

      const sortChatsByDate = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).sort((a, b) => {
            const timestampA = new Date(a[1].createdAt).getTime();
            const timestampB = new Date(b[1].createdAt).getTime();
            return timestampB - timestampA; // Sort in descending order
          })
        );
      };

      const sortedChats = sortChatsByDate(uniqueChatsLast15Days);
      setUniqueChatsLast15Days(sortedChats);
      setLoading(false);
      setRecentLoader(false);
      // console.warn(sortedChats, "Last 15 Days");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };

  const chatsToRender = searchTerm ? filteredChats : uniqueChatsLast15Days;
  const sortedChats = Object.entries(uniqueChatsLast15Days)
    .sort((a, b) => new Date(b[1].createdAt) - new Date(a[1].createdAt))
    .slice(0, 2);

  const generateChat = async () => {
    setApiData1([]);
    setApiData2([]);

    try {
      const token = sessionStorage.getItem("token");
      // console.log(token, "Token Received!!");
      console.log(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`
      );

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.chat_id, "Latest Chat Id");
      setChatId((chatIds = data.chat_id));
      console.log(chatIds, "ChatIds inside the box");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  const handleSendMessage = async () => {
    console.log(chatIds, "Chat Id is");
    setChatScreen(true);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    // setsuggestionare(false);
    // setShowDiv(!showDiv);

    const content = inputValue.trim();
    if (content) {
      setInputData(content);
      const userMessage: IMessage = {
        id: uuidv4(),
        name: "user",
        type: "user_message",
        output: content,
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const fetchingMessage: IMessage = {
        id: uuidv4(),
        name: "me",
        type: "bot_message",
        output: "Fetching Data..",
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, fetchingMessage]);

      // console.log(content, "Input Data");
      setInputValue(""); // Clear input field
      setShowLoader(true);

      try {
        const token = sessionStorage.getItem("token");
        console.log(chatIds, "Chat id i received is in handle message");
        const link =
          sessionStorage.getItem("userRole") === "Ally-User"
            ? "query-file"
            : "support-query-file";

        const response = await fetch(
          `${process.env.REACT_APP_LOCALHOST_API_LINK}/${link}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: content,
              chatId: chatIds,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        if (response.ok) {
          setShowingSuggestions(true);
        }

        const data = await response.json();
        console.log(data, "Data Response");
        const res = data.llmQueryResponse;
        const relevantLinks = data.relevantLinks || [];
        const followupQuestions = data.follow_up_questions || [];
        if (res) {
          getChats();
        }

        const botMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: res,
          createdAt: new Date().toISOString(),
          isNew: true,
          relevantLinks: relevantLinks,
          followupQuestions: followupQuestions,
        };

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === fetchingMessage.id ? botMessage : msg
          )
        );
        setCurrentMessageIndex(messages.length - 1);

        const response2 = await fetch(
          `${process.env.REACT_APP_SERVER_API_LINK}/jira-query`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              question: content,
            }),
          }
        );

        const data2 = await response2.json();
        console.log(data2, "Jira Query Response");
        const response3 = await fetch(
          `${process.env.REACT_APP_SERVER_API_LINK}/zendesk-query`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_query: content,
            }),
          }
        );

        const data3 = await response3.json();
        console.log(data3, "Zendesk Query Response");

        if (data2) {
          setApiData1(data2);
        }

        if (data3) {
          setApiData2(data3);
        }

        // Clear location state after handling the message
        // Example if using a state manager or context:
        // setLocationState(null);
      } catch (error) {
        console.error("Error sending message:", error);

        const errorMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: "There was an error. Can you please retry?",
          createdAt: new Date().toISOString(),
        };

        setMessages((prevMessages) => [...prevMessages, errorMessage]);
        setInputValue("");
      } finally {
        setShowLoader(false);
      }
    }
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setShowSubmit(value.trim().length > 0);
  };

  const handleClickChatEntry = async (chatId) => {
    console.log(chatId, "Chat Id is");
    setApiData1([]);
    setApiData2([]);
    setInputValue("");
    console.log(chats, "Chats are this");
    setShowLoader(true);
    setChatScreen(true);
    setChatId((chatIds = chatId));
    console.log(chatIds, "Chat Ids are this");
    console.log(chatId, "Current Chatting ID");
    const chatHistory = chats[chatId];
    console.log(chatHistory, "Chat History History");
    const updatedMessages = Array.isArray(chatHistory)
      ? chatHistory
      : [chatHistory];
    console.log(updatedMessages, "Updated Messages");
    const filteredUserChats = updatedMessages.filter(
      (data) => data.user === sessionStorage.getItem("email")
    );
    const userFilteredOne = filteredUserChats.reverse();
    console.log(userFilteredOne, "Filtered One");

    const processedMessages = updatedMessages.map((msg) => {
      console.log(msg, "message");
      if (msg.user === sessionStorage.getItem("userRole")) {
        console.warn("inside this ");

        return {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: msg.response,
          createdAt: new Date().toISOString(),
          isNew: false,
        };
      } else {
        return {
          id: uuidv4(),
          name: "user",
          type: "user_message",
          output: msg.response,
          createdAt: new Date().toISOString(),
          isNew: false,
        };
      }
    });
    setMessages(processedMessages);
    console.log(messages, "Messages");
    const token = sessionStorage.getItem("token");

    setShowLoader(true);
    const response2 = await fetch(
      `${process.env.REACT_APP_SERVER_API_LINK}/jira-query`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          question: userFilteredOne[0].response,
        }),
      }
    );
    const data2 = await response2.json();
    console.warn(messages, "Messchin");
    console.log(data2, "Response 2");
    if (data2) {
      setApiData1(data2);
      setShowLoader(false);
    }
    setShowLoader(true);
    const response3 = await fetch(
      `${process.env.REACT_APP_SERVER_API_LINK}/zendesk-query`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_query: userFilteredOne[0].response,
        }),
      }
    );
    const data3 = await response3.json();
    console.warn(messages, "Messchin");
    console.log(data3, "Response 2");
    if (data3) {
      setApiData2(data3);
      setShowLoader(false);
    }
  };

  const [checked, setChecked] = useState({
    query: false,
    product: false,
  });

  const handleSpanClick = (type) => {
    setChecked((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  const [liked, setLiked] = useState(false);
  const [lastMessage, setLastMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [ticketCreated, setTicketCreated] = useState(false);

  const [showMessage, setShowMessage] = useState(false);
  const [links, setLinks] = useState([]);
  const messagesHistoryRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [clickedButton, setClickedButton] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRow, setShowRow] = useState(true);
  const [likedMessages, setLikedMessages] = useState({});
  const [thankMessages, setThankMessages] = useState([]);
  const messagess: string[] = [
    "Thank you so much for your kind words!",
    "Your feedback made my day, thank you!",
    "I'm glad I could assist you!",
    "Thanks a bunch for your positive response!",
    "Your appreciation means a lot to me!",
    "I appreciate your encouraging feedback!",
    "Thanks for letting me know I could help!",
    "Your gratitude is truly appreciated!",
    "It's always a pleasure to assist you!",
    "Thank you for recognizing my efforts!",
  ];

  const [disLikedMessages, setdisLikedMessages] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const [ticketUrl, setTicketUrl] = useState("");

  const [email, setEmail] = useState("");
  const [uniqueChatsToday, setUniqueChatsToday] = useState({});
  const [uniqueChatsLast7Days, setUniqueChatsLast7Days] = useState({});
  const [uniqueChatsLast30Days, setUniqueChatsLast30Days] = useState({});
  const [uniqueChatsYesterday, setUniqueChatsYesterday] = useState({});
  // const [uniqueChatsLast15Days, setUniqueChatsLast15Days] = useState({});
  // let [currentChatId, setCurrentChatId] = useState("");
  // const [showLoader, setShowLoader] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [hoveredChatId, setHoveredChatId] = useState("");
  const [fromHandle, setFromHandle] = useState(true);
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(-1);
  const [likeColor, setLikeColor] = useState("black");
  const [likeColor1, setLikeColor1] = useState("black");
  let [con, setContent] = useState("");
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [filteredChats, setFilteredChats] = useState([]);
  let [divData, setdivData] = useState(false);
  let [imageData, setImageData] = useState(false);
  // let [inputData, setInputData] = useState("");
  const [chatGenerated, setChatGenerated] = useState(false);
  const [feedbackState, setFeedbackState] = useState({});
  const [topicChatIds, setTopicChatIds] = useState<string[]>([]);
  let [link, setLink] = useState("");
  let [releases, setReleases] = useState("");
  let [ticketData, setTicketData] = useState([]);
  const [linksPerMessage, setLinksPerMessage] = useState({});

  const [activePersona, setActivePersona] = useState(
    sessionStorage.getItem("userRole")
  );

  useEffect(() => {
    // console.log(chats, "Chats Is Received");
  });
  useEffect(() => {
    if (!chats || Object.keys(chats).length === 0) {
      setTopicChatIds([]); // Set topicChatIds to empty array if chats is undefined, null, or empty
      return;
    }

    const chatIds = Object.keys(chats);
    const selectedChatIds = [];

    // Select up to 5 random chatIds from chats or repeat if fewer than 5
    for (let i = 0; i < 5; i++) {
      const chatId = chatIds[i % chatIds.length]; // Use modulo to cycle through available chatIds
      selectedChatIds.push(chatId);
    }

    setTopicChatIds(selectedChatIds);
  }, [chats]);

  const goToProfile = () => {
    navigate("/dashboard/profile");
  };
  const handleButtonClick = (item, messageId) => {
    if (item === "Create ticket") {
      console.log("CREATE TICKET");
      setShowModal(true);
      setTicketUrl("");
      setTicketCreated(false);
    } else {
      setFeedbackState((prevState) => ({
        ...prevState,
        [messageId]: "feedbackReceived",
      }));
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_LINK}/release_notes/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      // console.log(data, "data fetched ");
      if (data) {
        setLink((link = data.url));
        setReleases((releases = data.releases));
      }
      // console.log(link, "link");
    } catch {
      console.log("Error");
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const [showAlert, setShowAlert] = useState(false);

  const [topicsz] = useState([
    { id: "e0f8e72a-a643-4bc2-873b-0fd0ef1e13e5", name: "Password Generation" },
    { id: "f6029972-3973-4861-a7ce-60b4d974ed44", name: "Jira Tickets" },
    { id: "10008285-0b4d-40d9-9442-268956bd4cca", name: "Customer Portal" },
    { id: "bdcdf1e4-2938-42e7-aece-60f7f1f18666", name: "Zendesk Queries" },
    { id: "e0f8e72a-a643-4bc2-873b-0fd0ef1e13e5", name: "Analytics" },
  ]);
  // const handleFormSubmit = (event) => {
  //   event.preventDefault();
  //   setShowModal(false);
  //   alert("Ticket Created Successfully");
  // };

  useEffect(() => {
    getChats();
  }, []);
  const getFormattedTime = (dateString) => {
    const serverDate = new Date(dateString);

    // Convert server date (assumed to be in UTC) to local time
    const localDate = new Date(
      serverDate.getTime() - serverDate.getTimezoneOffset() * 60000
    );

    // console.log("Local Date:", localDate);

    return formatDistanceToNow(localDate, { addSuffix: true });
  };

  // useEffect(() => {
  //   if (!chatGenerated) {
  //     console.log("true","True")
  //     generateChat();
  //     setChatGenerated(true);
  //   }

  // }, [chatGenerated]);
  useEffect(() => {
    console.log(chatIds, "SET CHAT ID");
    if (chatIds === "") {
      console.log("Tell me the thing");
      generateChat();
    }
  }, []);
  // useEffect(() => {
  //   if (currentChatId) {
  //     setChatId((chatIds = currentChatId));
  //     // console.log(currentChatId, "CurrentChaDDD");

  //     // console.log(chatIds, "ChatIds INVOKED");
  //   }

  //   if (messagesHistoryRef.current) {
  //     messagesHistoryRef.current.scrollTop =
  //       messagesHistoryRef.current.scrollHeight;
  //   }
  // }, [messages, firstLoad]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    const requestData = {
      subject: formData.get("subject"),
      body: formData.get("body"),
      ticket_type: "question",
      priority: "normal",
    };

    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/create_zendesk-ticket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      // console.log(data, "data is viewed");

      if (!response.ok) {
        throw new Error("Failed to create ticket");
      }

      if (data) {
        setTicketUrl(data.ticket_url);
        setTicketCreated(true);
      }

      form.reset();
      // handleCloseModal();
      // setShowAlert(true);
    } catch (error) {
      console.error("Error creating ticket:", error.message);
    }
  };

  const handleLike = async (messageId) => {
    setLikedMessages((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }));

    if (disLikedMessages[messageId]) {
      setdisLikedMessages((prevState) => ({
        ...prevState,
        [messageId]: false,
      }));
    }

    const randomMessages = Array.from(
      { length: 10 },
      () => messages[Math.floor(Math.random() * messages.length)]
    );
    setThankMessages(randomMessages);

    const token = sessionStorage.getItem("token");
    const response = await fetch(
      `${process.env.REACT_APP_LOCALHOST_API_LINK}/submit_feedback/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          input_value: 0,
        }),
      }
    );
    const data = await response.json();
  };

  const handleDisLike = async (messageId) => {
    setdisLikedMessages((prevState) => ({
      ...prevState,
      [messageId]: !prevState[messageId],
    }));

    if (likedMessages[messageId]) {
      setLikedMessages((prevState) => ({
        ...prevState,
        [messageId]: false,
      }));
    }

    setFeedbackState((prevState) => ({
      ...prevState,
      [messageId]: "feedbackOptions",
    }));

    const token = sessionStorage.getItem("token");
    const response = await fetch(
      `${process.env.REACT_APP_LOCALHOST_API_LINK}/submit_feedback/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          input_value: 1,
        }),
      }
    );
    const data = await response.json();
  };

  const renderMessage = (message, index) => {
    const isCurrentMessage = message === messages[messages.length - 1];
    // console.log(message.type,"Message.type")

    return (
      <li
        key={message.id}
        className={message.type === "user_message" ? "repaly" : "sender"}
      >
        <span>
          <br />
          <p>
            {message.type === "user_message" ? (
              <>
                <span style={{ display: "flex", alignItems: "flex-start" }}>
                  <img
                    src={message.type === "user_message" ? userlogo : gingrally}
                    alt=""
                    width={message.type === "user_message" ? 30 : 70}
                    style={{ marginRight: "10px" }} // Adjust margin as needed
                  />
                  <span
                    style={{ flex: 1, textAlign: "justify", marginTop: "4px" }}
                  >
                    {message.output}
                  </span>
                </span>
              </>
            ) : (
              <>
                <span>
                  <img
                    src={message.type === "user_message" ? userlogo : gingrally}
                    alt=""
                    width={message.type === "user_message" ? 30 : 70}
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                  <span style={{ marginTop: "4px", paddingLeft: "35px" }}>
                    {isCurrentMessage ? (
                      <TypeWriter text={message.output} speed={20} />
                    ) : (
                      <Indentation text={message.output} />
                    )}
                  </span>
                </span>
                <>
                  <div className="mt-2">
                    <span
                      className=""
                      style={{
                        color: likedMessages[message.id]
                          ? "dodgerblue"
                          : "black",
                        marginLeft: "5px",
                      }}
                    >
                      <i
                        className="fa fa-thumbs-up"
                        aria-hidden="true"
                        onClick={() => handleLike(message.id)}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>

                    <span
                      style={{
                        color: disLikedMessages[message.id] ? "red" : "black",
                        marginLeft: "10px",
                      }}
                    >
                      <i
                        className="fa fa-thumbs-down"
                        aria-hidden="true"
                        onClick={() => handleDisLike(message.id)}
                        style={{ cursor: "pointer" }}
                      ></i>
                      &nbsp; &nbsp;
                    </span>

                    <i
                      className="fa fa-refresh"
                      aria-hidden="true"
                      onClick={regenerate}
                      style={{ cursor: "pointer" }}
                    ></i>
                    <br />

                    {likedMessages[message.id] && (
                      <>
                        <div className="col-md-12 p-1 mt-3">
                          <span
                            className="message-box1 message-partner1"
                            style={{
                              marginBottom: "10px",
                              color: "gray",
                              marginTop: "10px",
                              fontWeight: "600",
                            }}
                          >
                            Glad was able to Help!
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {disLikedMessages[message.id] && (
                    <div className="col-md-12 d-flex ">
                      <div
                        className=""
                        style={{ width: "28px", display: "inline-block" }}
                      ></div>
                      <div
                        className="col-md-8 p-2 message-box message-partner mt-3 ml-3"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        {feedbackState[message.id] === "feedbackReceived" ? (
                          <div
                            style={{
                              marginTop: "",
                              color: "#022E57",
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            Thanks for the Feedback!
                          </div>
                        ) : (
                          <>
                            <h6
                              className="mb-3"
                              style={{
                                backgroundColor: "#E8F1F4",
                                fontWeight: "bold",
                              }}
                            >
                              Tell us More :
                            </h6>
                            <br />
                            <div
                              className="d-flex flex-wrap"
                              style={{
                                width: "100%",
                              }}
                            >
                              {[
                                "Response was incorrect !",
                                "Shouldn't have used memory for context",
                                "Should have used memory for context",
                                "Response is too slow !",
                                "Didn't followed the instructions",
                                "Create ticket",
                              ].map((item, index) => (
                                <div
                                  key={index}
                                  className="mb-2 d-flex"
                                  onClick={() =>
                                    handleButtonClick(item, message.id)
                                  }
                                  style={{
                                    marginLeft: "5px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <h6 className="responseNames">{item}</h6>
                                </div>
                              ))}

                              {ticketData}
                            </div>
                          </>
                        )}
                        <Modal show={showModal} onHide={handleCloseModal}>
                          <Modal.Header closeButton>
                            <Modal.Title>Create Ticket</Modal.Title>
                          </Modal.Header>
                          <Modal.Body className="custom-modal-body">
                            {!ticketCreated ? (
                              <Form onSubmit={handleFormSubmit}>
                                <Form.Group controlId="formSubject">
                                  <Form.Label>Subject</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="subject"
                                    placeholder="Enter subject"
                                    value={messages[messages.length - 2].output}
                                    required
                                  />
                                </Form.Group>

                                <Form.Group controlId="formBody">
                                  <Form.Label>Body</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    name="body"
                                    rows={3}
                                    placeholder="Enter body"
                                    required
                                    value={message.output}
                                  />
                                </Form.Group>

                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="customButton"
                                  style={{
                                    backgroundColor: "#022E57",
                                    border: "none",
                                  }}
                                >
                                  Create Ticket
                                </Button>
                              </Form>
                            ) : (
                              <div>
                                <p>Ticket created successfully!</p>
                                <Button
                                  variant="primary"
                                  href={ticketUrl}
                                  target="_blank"
                                  className="customButton"
                                  style={{
                                    backgroundColor: "#022E57",
                                    border: "none",
                                  }}
                                >
                                  Click to View Ticket
                                </Button>
                              </div>
                            )}
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  )}
                  {message.relevantLinks && (
                    <>
                      <div>
                        <div className="col-md-12 d-flex">
                          {message.relevantLinks.map((link, idx) => (
                            <li key={idx}>
                              <img
                                src={ExLink}
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  marginBottom: "2px",
                                }}
                                alt=""
                              />
                              <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#1894af",
                                  marginLeft: "5px",
                                  marginRight: "15px",
                                  fontSize: "12px",
                                  fontFamily: "Poppins",
                                  fontWeight: "600",
                                }}
                              >
                                usefull link {idx + 1}
                              </a>
                            </li>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  {isCurrentMessage && showingSuggestions && (
                    <>
                      <h6
                        className="mt-4"
                        style={{
                          fontWeight: "600",
                          color: "#2F9EB7",
                          cursor: "pointer",
                          fontSize: "12px",
                        }}
                        onClick={handleToggle1}
                      >
                        Try one of our suggestions
                        <i
                          className={`bx ${
                            isExpanded1 ? "bx-chevron-up" : "bx-chevron-down"
                          } bx-xs`}
                          style={{
                            cursor: "pointer",
                            marginLeft: "2px",
                            marginBottom: "20px",
                          }}
                          onClick={handleToggle1}
                        ></i>
                      </h6>
                      {isExpanded1 && (
                        <>
                          <SuggestionQuestion
                            questions={Object.values(
                              message.followupQuestions || {}
                            )}
                            onSelect={(question) => {
                              console.log("Selected:", question);
                              setInputValue((inputValue = question));
                              setShowingSuggestions(
                                (showingSuggestions = false)
                              );
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              </>
            )}
          </p>
          <br />
        </span>
      </li>
    );
  };

  const TopicList1 = ({ children }) => {
    return <ul>{children}</ul>;
  };

  const TopicItem1 = ({ topic }) => {
    const handleClick = () => {
      handleClickChatEntry(topic.id);
    };

    return (
      <li>
        <button onClick={handleClick} className="data">
          {topic.name}
        </button>
      </li>
    );
  };

  const topics = [
    "Password Generation",
    "Jira Analytics",
    "Zendesk Queries",
    "Customer Portal",
    "Analytics",
    "Gingr Login",
    "Notifications",
    "Pet Care",
  ];
  useEffect(() => {
    if (sessionStorage.getItem("selectedTicket")) {
      let data = JSON.parse(sessionStorage.getItem("selectedTicket")!);
      setInputValue((inputValue = data.subject));
    }
  }, [sessionStorage.getItem("selectedTicket")]);

  const regenerate = () => {
    console.log(messages, "Messages");
    const prevMessage = messages[messages.length - 2];

    if (prevMessage) {
      setInputValue((inputValue = prevMessage.output));
      handleSendMessage();

      setInputValue("");
    } else {
      console.log("No previous message to regenerate.");
    }
  };
  const chatScreenShow = async () => {
    setChatScreen(!chatScreen);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    try {
      const token = sessionStorage.getItem("token");
      console.log(token, "Token Received!!");
      console.log(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`
      );

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.chat_id, "Latest Chat Id");
      setChatId((chatIds = data.chat_id));
      console.log(chatIds, "ChatIds inside the box");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  const chatScreenShow1 = async () => {
    setShowChats(!showChats);
    setApiData([]);
    setApiData1([]);
    setApiData2([]);
    try {
      const token = sessionStorage.getItem("token");
      console.log(token, "Token Received!!");
      console.log(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`
      );

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/generate-chatId`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.chat_id, "Latest Chat Id");
      setChatId((chatIds = data.chat_id));
      console.log(chatIds, "ChatIds inside the box");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };

  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="min-vh-100 row p-0 m-0">
          <div className="row mt-3 px-3 minimzedscreen">
            <div className="col-12 d-flex justify-content-between">
              <i className="bx bx-menu bx-sm" style={{ cursor: "pointer" }}></i>
              <i
                className="bx bx-info-circle bx-sm"
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>

          <div
            className={`col-12 ${
              fullWidth ? "col-md-12" : "col-md-2"
            } p-0 m-0 sidebarcolumn`}
          >
            {sessionStorage.getItem("userRole") === "Ally-Support" && (
              <>
                <div
                  className="col-12 align-items-center m-0 p-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100vh",
                    overflow: "scroll",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="mt-1">
                    {chatScreen ? (
                      <>
                        <>
                          <div className="col-12">
                            <h6
                              className="text-center"
                              style={{
                                color: "var(--text-clr)",
                                fontWeight: "500",
                              }}
                            >
                              Your Chats
                            </h6>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div className="inputfieldcontainer">
                              <i className="bx bx-search search-icon"></i>
                              <input
                                type="text"
                                className="newinputfield"
                                placeholder="Search your chats..."
                                value={searchTerm}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            <div className="chat-container1">
                              <div className="previouschatsdiv">
                                {Object.entries(chatsToRender).map(
                                  ([chatId, chat]) => (
                                    <div
                                      key={chatId}
                                      className="chat-item"
                                      onClick={() => {
                                        handleClickChatEntry(chatId);
                                      }}
                                    >
                                      <div className="chat-message">
                                        {chat.firstWords}
                                      </div>
                                      <div className="chattime">
                                        {getFormattedTime(chat.createdAt)}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      </>
                    ) : (
                      <>
                        <Activities />
                      </>
                    )}
                    {/* {fullWidth && (
                      <>
                        <Activities />
                      </>
                    )}
                    {!fullWidth && (
                      <>
                        <div className="col-12">
                          <h6
                            className="text-center"
                            style={{
                              color: "var(--text-clr)",
                              fontWeight: "500",
                            }}
                          >
                            Your Chats
                          </h6>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="inputfieldcontainer">
                            <i className="bx bx-search search-icon"></i>
                            <input
                              type="text"
                              className="newinputfield"
                              placeholder="Search your chats..."
                              value={searchTerm}
                              onChange={handleSearch}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="chat-container1">
                            <div className="previouschatsdiv">
                              {Object.entries(chatsToRender).map(
                                ([chatId, chat]) => (
                                  <div
                                    key={chatId}
                                    className="chat-item"
                                    onClick={() => {
                                      handleClickChatEntry(chatId);
                                    }}
                                  >
                                    <div className="chat-message">
                                      {chat.firstWords}
                                    </div>
                                    <div className="chattime">
                                      {getFormattedTime(chat.createdAt)}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )} */}
                  </div>
                  <div
                    className="mb-4"
                    style={{
                      paddingRight: "16px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    <h1
                      style={{
                        color: "#1894af",
                        fontWeight: "700",
                        fontSize: "14px",
                        marginBottom: "20px",
                        letterSpacing: "1px",
                        textTransform: "uppercase",
                        textAlign: "center",
                        position: "relative",
                        fontFamily: "Poppins",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#ffffff",
                          padding: "0 10px",
                          position: "relative",
                          zIndex: 1,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                        }}
                      >
                        Product Update
                      </span>
                      <div
                        style={{
                          height: "2px",
                          backgroundColor: "#1894af",
                          position: "absolute",
                          bottom: "0",
                          left: "10%",
                          right: "10%",
                        }}
                      ></div>
                    </h1>

                    <h6
                      style={{
                        fontWeight: "600",
                        color: "#ed2121",
                        fontSize: "12px",
                        marginBottom: "10px",
                        textAlign: "center",
                      }}
                    >
                      Latest Releases
                    </h6>

                    {releases && releases.length > 0 ? (
                      releases.map((release, index) => (
                        <div
                          key={release.id}
                          style={{
                            borderBottom:
                              index !== releases.length - 1
                                ? "1px dashed #ccc"
                                : "none",
                            // position: "relative",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            <h3
                              style={{
                                color: "gray",
                                fontWeight: "1000",
                                fontSize: "14px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {release.name}
                            </h3>
                            <span
                              style={{
                                color: "gray",
                                fontWeight: "400",
                                fontSize: "12px",
                              }}
                            >
                              Release ID:{" "}
                              <span
                                style={{
                                  color: "#1894af",
                                  fontWeight: "bold",
                                }}
                              >
                                {release.id}
                              </span>
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "50px 0",
                        }}
                      >
                        <div
                          className="spinner-grow text-secondary"
                          role="status"
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "10px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-secondary"
                          role="status"
                          style={{
                            width: "15px",
                            height: "15px",
                            marginRight: "10px",
                          }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        <div
                          className="spinner-grow text-secondary"
                          role="status"
                          style={{ width: "15px", height: "15px" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    <div className="mt-3">
                      <a
                        href={link || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "red",
                          fontWeight: "800",
                          fontSize: "10px",

                          textAlign: "center",
                          textDecoration: "none",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          opacity: "0.8",
                        }}
                      >
                        Check More Releases...
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
            {sessionStorage.getItem("userRole") === "Ally-User" && (
              <>
                <div
                  className="col-12 align-items-center m-0 p-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100vh",
                    overflow: "scroll",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="col-12">
                    <div style={{ borderRadius: "8px", overflow: "hidden" }}>
                      <div
                        className="mt-2"
                        style={{
                          marginLeft: "2px",
                          fontSize: "1.0em",
                          fontWeight: "500",
                          borderRadius: "8px 8px 0 0",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          className=""
                          style={{
                            marginTop: "11px",
                            color: "#15325B",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                        >
                          Trending Topics
                        </h5>
                      </div>
                      <div className="col-12">
                        <div
                          className="d-grid"
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(120px, 1fr))",
                            gap: "8px",
                            cursor: "pointer",
                          }}
                        >
                          {topics.map((topic, index) => (
                            <div
                              key={index}
                              style={{
                                color: "#16283E",
                                textAlign: "center",
                                backgroundColor: "#FFFF",
                                wordWrap: "break-word",
                                fontSize: "12px",
                                alignItems: "center",
                                borderRadius: "10px",
                                border: "1px solid #f7f7f7",
                                justifyContent: "center",
                                fontWeight: "400",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                fontFamily: "Poppins",
                                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                transition:
                                  "background-color 0.3s, transform 0.3s",
                              }}
                              onClick={() =>
                                handleClickChatEntry(topicChatIds[index])
                              }
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  "#E8E8E8";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  "#FFFFFF";
                              }}
                            >
                              {topic}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <h5
                      style={{
                        color: "#15325B",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                      }}
                    >
                      Quick Note
                    </h5>
                    <p
                      className=""
                      style={{
                        textAlign: "justify",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        fontSize: "14px",
                        color: "black",
                        marginTop: "15px",
                      }}
                    >
                      Meeting minutes are notes that are recorded during a
                      meeting. They highlight the key issues that are discussed,
                      motions proposed or voted on, and activities to be
                      undertaken. The minutes of a meeting are usually taken by
                      a designated member of the group.The minutes of a meeting
                      are usually taken by a designated member.
                    </p>
                  </div>
                </div>
              </>
            )}
            {sessionStorage.getItem("userRole") === "Ally-Admin" && (
              <>
                <div
                  className="col-12 align-items-center m-0 p-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100vh",
                    overflow: "scroll",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="col-12">
                    <div style={{ borderRadius: "8px", overflow: "hidden" }}>
                      <div
                        className="mt-2"
                        style={{
                          marginLeft: "2px",
                          fontSize: "1.0em",
                          fontWeight: "500",
                          borderRadius: "8px 8px 0 0",
                          marginBottom: "10px",
                        }}
                      >
                        <h5
                          className=""
                          style={{
                            marginTop: "11px",
                            color: "#15325B",
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                        >
                          Trending Topics
                        </h5>
                      </div>
                      <div className="col-12">
                        <div
                          className="d-grid"
                          style={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(120px, 1fr))",
                            gap: "8px",
                            cursor: "pointer",
                          }}
                        >
                          {topics.map((topic, index) => (
                            <div
                              key={index}
                              style={{
                                color: "#16283E",
                                textAlign: "center",
                                backgroundColor: "#FFFF",
                                wordWrap: "break-word",
                                fontSize: "12px",
                                alignItems: "center",
                                borderRadius: "10px",
                                border: "1px solid #f7f7f7",
                                justifyContent: "center",
                                fontWeight: "400",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                fontFamily: "Poppins",
                                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
                                transition:
                                  "background-color 0.3s, transform 0.3s",
                              }}
                              onClick={() =>
                                handleClickChatEntry(topicChatIds[index])
                              }
                              onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  "#E8E8E8";
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                  "#FFFFFF";
                              }}
                            >
                              {topic}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <h5
                      style={{
                        color: "#15325B",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                      }}
                    >
                      Quick Note
                    </h5>
                    <p
                      className=""
                      style={{
                        textAlign: "justify",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        fontSize: "14px",
                        color: "black",
                        marginTop: "15px",
                      }}
                    >
                      Meeting minutes are notes that are recorded during a
                      meeting. They highlight the key issues that are discussed,
                      motions proposed or voted on, and activities to be
                      undertaken. The minutes of a meeting are usually taken by
                      a designated member of the group.The minutes of a meeting
                      are usually taken by a designated member.
                    </p>
                  </div>
                  <div className="p-1">
                    <div className="p-1">
                      {releases && releases.length > 0 ? (
                        <>
                          <h5
                            className=""
                            style={{
                              color: "#15325B",
                              fontFamily: "Poppins",
                              fontWeight: "400",
                            }}
                          >
                            Product Update
                          </h5>
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "#ed2121",
                              fontFamily: "Poppins",
                              fontWeight: "300",
                            }}
                          >
                            Releases:
                          </span>
                          {releases.map((release) => (
                            <div className="" key={release.id}>
                              <div
                                style={{
                                  textAlign: "justify",
                                  marginBottom: "20px",
                                  fontFamily: "Poppins",
                                  fontWeight: "300",
                                  marginTop: "20px",
                                }}
                              >
                                <h6 style={{ color: "gray" }}>
                                  {release.name}{" "}
                                  <span style={{ color: "red" }}> | </span>{" "}
                                  {release.id}
                                </h6>
                              </div>
                            </div>
                          ))}
                          <a
                            href={link || ""}
                            target="_blank"
                            style={{
                              marginTop: "20px",
                              fontFamily: "Poppins",
                              fontWeight: "300",
                            }}
                          >
                            Check More Releases...
                          </a>
                        </>
                      ) : (
                        <>
                          {/* <img
                            src={Loader}
                            className="mt-5"
                            alt="loaderImage"
                            style={{ width: "10px", height: "10px" }}
                          /> */}
                          <div
                            className="spinner-grow text-secondary"
                            role="status"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div
                            className="spinner-grow text-secondary"
                            role="status"
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "10px",
                            }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div
                            className="spinner-grow text-secondary"
                            role="status"
                            style={{ width: "20px", height: "20px" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </>
                      )}
                    </div>
                    <br />
                  </div>
                </div>
              </>
            )}
          </div>

          {!fullWidth && (
            <div
              className={`col-12 ${fullWidth ? "col-md-12" : "col-md-8"} p-0`}
              style={{ backgroundColor: "white" }}
            >
              <div className="container-fluid aichatdiv">
                {loading ? (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100vh" }}
                    >
                      <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row">
                      {!chatScreen && (
                        <>
                          {" "}
                          {!showChats && (
                            <>
                              <div className="col-12 mt-5">
                                <h4 className="text-center">Ally Assistant</h4>
                                <h1
                                  className="text-center mb-4 mt-4"
                                  style={{
                                    color: "var(--text-clr)",
                                    fontWeight: "500",
                                  }}
                                >
                                  {greeting},{" "}
                                  {sessionStorage.getItem("userName")}
                                </h1>
                              </div>

                              <form
                                action=""
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  handleSendMessage();
                                }}
                              >
                                <div className="col-12 d-flex justify-content-center container">
                                  <textarea
                                    ref={textareaRef}
                                    className="mainArea"
                                    placeholder="How can Ally assist you with today?"
                                    onInput={handleInput}
                                    onChange={(e) =>
                                      setInputValue(e.target.value)
                                    }
                                    value={inputValue}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSendMessage();
                                      }
                                    }}
                                  ></textarea>
                                  {showSubmit && (
                                    <span
                                      className="submit"
                                      onClick={handleSendMessage}
                                    >
                                      <i
                                        className="bx bx-up-arrow-alt bx-sm"
                                        style={{
                                          margin: "5px",
                                          color: "white",
                                        }}
                                      ></i>
                                    </span>
                                  )}
                                  {showSubmit && (
                                    <>
                                      <span className="submit1">
                                        <input
                                          type="checkbox"
                                          style={{ marginRight: "5px" }}
                                        />
                                        Query Data
                                      </span>
                                      <span className="submit2">
                                        <input
                                          type="checkbox"
                                          style={{ marginRight: "5px" }}
                                        />
                                        Product Data
                                      </span>
                                    </>
                                  )}
                                </div>
                              </form>

                              {!hideSuggestions && (
                                <div className="d-flex justify-content-center">
                                  <div className="mainArea1 p-2">
                                    <div className="col-12 p-0 m-0 d-flex justify-content-between">
                                      <span className="tryonetext">
                                        Try one of our suggestions!
                                      </span>
                                      <div>
                                        <span className="docstext">
                                          <i
                                            className="bx bx-refresh bx-sm"
                                            style={{
                                              cursor: "pointer",
                                              color: "var(--primary-color)",
                                            }}
                                            onClick={refreshNames1}
                                            onMouseEnter={() => {
                                              setShowSuggestions(true);
                                              setSuggestion(
                                                "Reload Suggestions"
                                              );
                                            }}
                                            onMouseLeave={() => {
                                              setShowSuggestions(false);
                                              setSuggestion("");
                                            }}
                                          ></i>
                                          <i
                                            className="bx bx-x bx-sm closeicon"
                                            style={{
                                              cursor: "pointer",
                                              color: "var(--primary-color)",
                                            }}
                                            onMouseEnter={() => {
                                              setShowSuggestions(true);
                                              setSuggestion("Hide Suggestions");
                                            }}
                                            onMouseLeave={() => {
                                              setShowSuggestions(false);
                                              setSuggestion("");
                                            }}
                                            onClick={performAction}
                                          ></i>
                                        </span>
                                        <span
                                          className="spanclass"
                                          style={{
                                            position: "absolute",
                                            left: "68.8%",
                                            top: "38%",
                                            borderRadius: "10px",
                                            padding: "6px",

                                            fontSize: "12px",
                                            display: showSuggestions
                                              ? "block"
                                              : "none",
                                            fontFamily: "Poppins",
                                            fontWeight: "500",
                                            letterSpacing: "1px",
                                            backgroundColor: "#1894af",
                                          }}
                                        >
                                          {suggestion}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row p-0 m-0 mt-3 showdata">
                                      {currentSuggestions &&
                                      currentSuggestions.length > 0 ? (
                                        <>
                                          {" "}
                                          {currentSuggestions.map(
                                            (suggestion, index) => (
                                              <div
                                                key={index}
                                                className="col-md-4 mb-2 sugges-item"
                                                style={{ position: "relative" }}
                                                onClick={() =>
                                                  setinputmessage(
                                                    suggestion.key
                                                  )
                                                }
                                              >
                                                <div
                                                  style={{
                                                    padding: "5px",
                                                    borderRadius: "10px",
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    backgroundColor: "#1894af",
                                                    color:
                                                      "var(--background-color)",
                                                    fontWeight: "400",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  <span className="datas">
                                                    {suggestion.key
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      suggestion.key.slice(1)}
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <div
                                              className="spinner-grow text-secondary"
                                              role="status"
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                                marginRight: "10px",
                                              }}
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                            <div
                                              className="spinner-grow text-secondary"
                                              role="status"
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                                marginRight: "10px",
                                              }}
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                            <div
                                              className="spinner-grow text-secondary"
                                              role="status"
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                              }}
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                            {/* <img
                          src={Loader}
                          alt="Loading..."
                          style={{ width: "40px", height: "40px" }}
                        /> */}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="d-flex justify-content-center">
                                <div className="chatsdiv d-flex p-3 justify-content-between">
                                  <div className="d-flex">
                                    <i
                                      className="bx bx-message-dots bx-xs"
                                      style={{ color: "#1c9aaa" }}
                                    ></i>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        width: "10px",
                                      }}
                                    ></div>
                                    <h6
                                      style={{
                                        fontSize: "12px",
                                        color: "gray",
                                      }}
                                    >
                                      Your recent chats
                                    </h6>
                                    <i
                                      className={`bx ${
                                        isExpanded
                                          ? "bx-chevron-up"
                                          : "bx-chevron-down"
                                      } bx-xs`}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "8px",
                                        marginTop: "-2px",
                                      }}
                                      onClick={handleToggle}
                                    ></i>
                                    {!isExpanded && (
                                      <span
                                        className="show-chats-text showChatsDiv"
                                        onClick={handleToggle}
                                        style={{
                                          cursor: "pointer",
                                          color: "#0d768b",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Show chats
                                      </span>
                                    )}
                                  </div>
                                  <div className="d-flex">
                                    <h6
                                      style={{
                                        fontSize: "12px",
                                        color: "gray",
                                        cursor: "pointer",
                                      }}
                                      onClick={listChats}
                                    >
                                      View all
                                    </h6>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        width: "5px",
                                      }}
                                    ></div>
                                    <i
                                      className="bx bx-right-arrow-alt"
                                      style={{
                                        color: "#1c9aaa",
                                        cursor: "pointer",
                                      }}
                                      onClick={listChats}
                                    ></i>
                                  </div>
                                </div>
                              </div>

                              {isExpanded && (
                                <div className="d-flex justify-content-center">
                                  <div className="chatsdiv d-flex p-3 justify-content-between">
                                    <div className="row col-12 d-flex">
                                      {sortedChats.map(([chatId, chat]) => (
                                        <div
                                          key={chatId}
                                          className="col-md-4 m-1 p-3 chatshowcase"
                                          style={{
                                            border: "1px solid #F6F1F0",
                                            borderRadius: "20px",
                                          }}
                                          onClick={() => {
                                            handleClickChatEntry(chatId);
                                          }}
                                        >
                                          <span>
                                            <i
                                              className="bx bx-message-dots bx-sm"
                                              style={{ color: "#1c9aaa" }}
                                            ></i>
                                            <h6
                                              style={{
                                                fontSize: "14px",
                                                color: "gray",
                                                marginTop: "10px",
                                              }}
                                            >
                                              {chat.firstWords}
                                            </h6>
                                            <h6
                                              style={{
                                                fontSize: "10px",
                                                color: "gray",
                                                marginTop: "10px",
                                              }}
                                            >
                                              {/* {formatDistanceToNow(
                                              new Date(chat.createdAt),
                                              { addSuffix: true }
                                            )} */}
                                              {getFormattedTime(chat.createdAt)}
                                            </h6>
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {showChats && (
                            <>
                              <div className="col-12 d-flex justify-content-between">
                                <div onClick={chatScreenShow1} className="mt-3">
                                  <i
                                    className="bx bx-left-arrow-alt bx-sm"
                                    style={{
                                      marginRight: "15px",
                                      color: "gray",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>

                                <div
                                  className="button-container"
                                  onClick={listChats}
                                >
                                  <button className="start-chat-button">
                                    <i
                                      className="bx bx-plus-circle bx-sm"
                                      style={{ marginRight: "15px" }}
                                    ></i>
                                    <span className="button-text">
                                      Start new chat
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-12">
                                <h3
                                  className="text-center mb-4 mt-4"
                                  style={{
                                    color: "var(--text-clr)",
                                    fontWeight: "500",
                                  }}
                                >
                                  Your Chats
                                </h3>
                              </div>
                              <div className="d-flex justify-content-center">
                                <div className="inputfieldcontainer">
                                  <i className="bx bx-search search-icon"></i>
                                  <input
                                    type="text"
                                    className="newinputfield"
                                    placeholder="Search your chats..."
                                    value={searchTerm}
                                    onChange={handleSearch}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-center">
                                <div className="chat-container">
                                  <div className="previouschatsdiv">
                                    {Object.entries(chatsToRender).map(
                                      ([chatId, chat]) => (
                                        <div
                                          key={chatId}
                                          className="chat-item"
                                          onClick={() => {
                                            handleClickChatEntry(chatId);
                                          }}
                                        >
                                          <div className="chat-message">
                                            {chat.firstWords}
                                          </div>
                                          <div className="chattime">
                                            {getFormattedTime(chat.createdAt)}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {chatScreen && (
                        <>
                          <div className="col-md-12">
                            <div className="col-12 d-flex justify-content-between">
                              <div onClick={chatScreenShow} className="mt-3">
                                <i
                                  className="bx bx-left-arrow-alt bx-sm"
                                  style={{
                                    marginRight: "15px",
                                    color: "gray",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                              <div
                                className="button-container"
                                onClick={listChats1}
                              >
                                <button className="start-chat-button">
                                  <i
                                    className="bx bx-plus-circle bx-xs"
                                    style={{ marginRight: "5px" }}
                                  ></i>
                                  <span
                                    className="button-text"
                                    style={{ marginTop: "2px" }}
                                  >
                                    Start new chat
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div id="Chatpage">
                            <section className="message-area">
                              <div className="container-fluid p-0 m-0">
                                <div className="row">
                                  <div
                                    className="col-md-12 m-0"
                                    style={{
                                      borderRight: "1px solid lightgrey",
                                    }}
                                  >
                                    <div className="chat-area p-1">
                                      <div className="chatbox">
                                        <div className="modal-dialog-scrollable">
                                          <div className="modal-content">
                                            <div className="">
                                              <div className="row">
                                                <div className="col-8">
                                                  <div className="d-flex align-items-center">
                                                    <span className="chat-icon">
                                                      <img
                                                        className="img-fluid"
                                                        src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                                        alt="image title"
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="col-4">
                                                  <ul className="moreoption"></ul>
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className="modal-body"
                                              ref={divRef}
                                              style={{
                                                overflowY: "auto",
                                                height: "100vh",
                                              }}
                                            >
                                              <div className="msg-body">
                                                <ul
                                                  className="messages"
                                                  style={{ paddingLeft: "0" }}
                                                >
                                                  {messages.map(
                                                    (message, index) =>
                                                      renderMessage(
                                                        message,
                                                        index
                                                      )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                            <div className="send-box">
                                              <form
                                                onSubmit={(e) => {
                                                  e.preventDefault();
                                                  handleSendMessage();
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  aria-label="message…"
                                                  placeholder="Write message…"
                                                  onChange={(e) =>
                                                    setInputValue(
                                                      (inputValue =
                                                        e.target.value)
                                                    )
                                                  }
                                                  value={inputValue}
                                                />
                                                <button
                                                  type="button"
                                                  onClick={handleSendMessage}
                                                >
                                                  <i
                                                    className="fa fa-paper-plane"
                                                    aria-hidden="true"
                                                  />{" "}
                                                </button>
                                              </form>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {showButton && (
                                        <button
                                          className="scroll-to-bottom-button show"
                                          onClick={scrollToBottom}
                                        >
                                          <i
                                            className="fa fa-chevron-down"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>

              {/* <AIChat /> */}
            </div>
          )}

          {!fullWidth && (
            <div
              className="col-12 col-md-2 px-3"
              style={{
                backgroundColor: "white",
                border: "1px solid #F5F5F5",
              }}
            >
              <div className="">
                {activePersona === "Ally-Support" && (
                  // <></>
                  <T1support
                    data={apiData1}
                    zendeskData={apiData2}
                    extraData={showLoader}
                    divData={divData}
                    imageData={imageData}
                    relevantTickets={undefined}
                  />
                )}
                {activePersona === "Ally-User" && (
                  <Customerpersona
                    data={apiData1}
                    extraData={showLoader}
                    zendeskData={apiData2}
                    divData={divData}
                    iamgeData={undefined}
                    relevantLinkss={apiData?.relevantLinks || undefined}
                  />
                )}
                {activePersona === "Ally-Admin" && (
                  <T1support
                    data={apiData1}
                    zendeskData={apiData2}
                    extraData={showLoader}
                    divData={divData}
                    imageData={imageData}
                    relevantTickets={undefined}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { Chat };

const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled.div``;

const TopicList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 5px;
  justify-items: center;
`;

const TopicItem1 = styled.div`
  width: 130px;
  height: 45px;
  background: #4682b4;
  color: white;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background: white;
    color: black;
    cursor: pointer;
    border-radius: 15px;
  }
`;

const styles = {
  ticketDiv: {
    backgroundColor: "#fafafa",
    border: "1px solid lightgray",
    padding: "10px 10px",
    borderRadius: "5px",
    marginBottom: "15px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    textAlign: "justify",
    "&::hover": {
      backgroundColor: "blue",
      color: "white",
    },
  },
};
