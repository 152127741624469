import React from "react";
import "./CustomerTickets.css";

const CustomerTickets = () => {
  return (
    <>
      <div className="container-fluid p-0 m-0">
        <div className="row p-0 m-0">
          <div className="col-12 col-md-12">
            <h6 className="relevanttext mt-1">Relevant Issues</h6>
            <div className="zendesktickets">
              <div className="inditickets">
              </div>
            </div>

         
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomerTickets;
