import React from "react";
import logo from "../../assets/images/allylogo.png";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      {/* Footer */}
      <div id="footer_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2">
              
            </div>
            <div className="col-md-10">
              
            </div>
            {/* <div className="col-md-2">
              <div className="logo" style={{cursor:'pointer'}} >
                <img src={logo} alt="logo" style={{ width: 100 }} onClick={()=>navigate('/')} />
              </div>
            </div> */}
            {/* <div className="col-md-10">
              <div className="cnt">
                <p className="text-gray-400">
                  © 2024 Ally. Design &amp; Developed By{" "}
                  <i class="mdi mdi-heart text-orange-700"></i> by{" "}
                  <a
                    target="_blank"
                    className="text-reset"
                    href="https://allshoretech.com/"
                  >
                    AllShore Technologies
                  </a>
                  .
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
