import React, { useEffect, useState } from "react";
import teams from "../../assets/images/Homepage/teams.png";
import jira from "../../assets/images/jira.svg";
import loading from "../../assets/images/loading-unscreen.gif";
import zendesk from "../../assets/images/zendesk.png";
import ExLink from "../../assets/images/exlink.png"

export const Customerpersona = ({
  data,
  extraData,
  divData,
  iamgeData,
  zendeskData,
  relevantLinkss,
}) => {
  const [show, setShow] = useState(true);
  let [ticket, setTickets] = useState([]);
  let [zendeskTicket, setZendeskTicket] = useState([]);
  let [relevantLinks, setRelevantLinks] = useState([]);

  const [load, setLoad] = useState(false);
  console.log(data, "jiraETIc");
  console.log(extraData, "datsa");
  console.log(relevantLinkss, "Relevant Links Received");

  useEffect(() => {
    if (data) {
      console.log(ticket, "Ticket BEfore");
      setTickets((ticket = data.jira_tickets));
      console.log(ticket, "Ticket After");

      setLoad(false);
    } else {
      setLoad(true);
    }
    if (zendeskData) {
      console.log(ticket, "Zendesk Ticket BEfore");
      setZendeskTicket((zendeskTicket = zendeskData.related_tickets));
      console.log(zendeskTicket, "Zendesk Ticket After");

      setLoad(false);
    }
    if (relevantLinkss) {
      setRelevantLinks((relevantLinks = relevantLinkss));
      console.log(relevantLinks, "Relevant Links Received");

      setLoad(false);
    }
  }, [data, zendeskData, relevantLinkss]);

  useEffect(() => {
    setLoad(extraData);
  }, [extraData]);

  return (
    <div>
      <div className="container p-0 m-0">
        <div className="row ">
          <div className="col-md-12 p-1">
            <div className="productUpdate">
              <div
                className="d-flex"
                style={{
                  height: "100vh",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="mb-4"
                  style={{ height: "", overflow: "hidden" }}
                >
                  <h6 className="mt-3 mb-3" style={{fontFamily:"Poppins"}}>Relevant Issues</h6>

                  <div
                    id="ticketsDiv"
                    style={{
                      height: "",
                      overflowY: "auto",
                      boxSizing: "content-box",
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                  >
                    {zendeskTicket && zendeskTicket.length > 0 ? (
                      <>
                        <div className="ticketsDiv1">
                          {zendeskTicket && zendeskTicket.length > 0 ? (
                            zendeskTicket.map((item, index) => (
                              <div key={item.id} className="ticketItem">
                                <a
                                  href={item.url}
                                  target="_blank"
                                  className="link"
                                >
                                  <img
                                    src={zendesk}
                                    alt=""
                                    className="smallicon"
                                  />{" "}
                                  &nbsp;
                                  <strong>{item.id}</strong>
                                  <div className="summary">
                                    {item.description}
                                  </div>
                                </a>
                              </div>
                            ))
                          ) : load ? (
                            <div style={styles.loadingContainer}>
                              <img
                                src={loading}
                                alt=""
                                style={styles.loadingIcon}
                              />
                              <p style={styles.loadingText}>
                                Fetching Related Issues
                              </p>
                            </div>
                          ) : (
                            <p style={styles.noTicketsText}>
                              No Relevant Issues Found !!
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        {!load ? (
                          <p style={{fontFamily:"Poppins",fontWeight:'400'}}>No Zendesk Issues Found !!</p>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <img
                              src={loading}
                              alt=""
                              style={{ width: "40px", height: "40px" }}
                            />
                            <p style={{ marginTop: "8px", marginLeft: "10px" }}>
                              Fetching Zendesk Related Issues
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="mb-2">
                  <h5 style={{fontFamily:"Poppins"}}>Helpful Resources</h5>
                  {relevantLinks && relevantLinks.length > 0 ? (
                    <div>
                        <div className="col-md-12 d-flex">
                          {relevantLinks.map((link, idx) => (
                              <li key={idx}>
                              <img
                                src={ExLink}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginBottom: "2px",
                                }}
                                alt=""
                              />
                              <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="important"
                                style={{
                                  color: "#1894af",
                                  marginLeft: "5px",
                                  marginRight: "15px",
                                  fontSize: "16px",
                                }}
                              >
                                Resource {idx + 1}
                              </a>
                            </li>
                          ))}
                        </div>
                      </div>
                  ) : (
                    <>
                      <p style={{fontFamily:"Poppins"}}>No Links Found!!</p>
                    </>
                  )}

                  {/* {relevantLinks && relevantLinks > 0 ? (
                    <>
                      {relevantLinks.map((link, idx) => (
                        <li key={idx}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Link {idx + 1}
                          </a>
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      <p>No Links Found!!</p>
                    </>
                  )} */}

                  {/* <a
                    href="https://support.gingrapp.com/hc/en-us/articles/4411778759053-Customer-Portal-Overview-Customer-Portal-2-0"
                    target="_blank"
                  >
                    <i className="fa fa-thumb-tack" aria-hidden="true"></i>{" "}
                    &nbsp; Gingr Payments
                  </a>
                  <br /> */}
                  {/* <a
                    href="https://support.gingrapp.com/hc/en-us/articles/12385037148941-Using-Google-Tag-Manager-and-Facebook-Pixel-in-Customer-Portal-2-0"
                    target="_blank"
                  >
                    <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                    &nbsp; Submitting a New Account
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    padding: "10px 10px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "15px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
  icon: {
    width: "30px",
  },
  ticketText: {
    fontSize: "16px",
    fontWeight: "600",
    flexGrow: 1,
    marginLeft: "15px",
  },
  numbers: {
    // fontSize: "18px",
    // fontWeight: "bold",
    // color: "#333",
    width: "30px",
    height: "30px" /* Added height to ensure circular shape */,
    lineHeight: "30px",
    borderRadius: "50%",
    textAlign: "center",
    backgroundColor: "black",
    margin: "0 10px" /* Adjust margin to keep elements aligned */,
    color: "white",
  },
  toggleIcon: {
    width: "15px",
    height: "15px",
  },
  ticketsDiv: {
    // padding: "15px 20px",
    // backgroundColor: "#ffffff",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    borderRadius: "10px",
    marginBottom: "15px",
    overflowY: "auto",
  },
  summary: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    color: "white",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2, // Limit to 2 lines
    lineHeight: "1.5em", // Adjust line height as needed
    maxHeight: "3em",
    padding: "2px", // Line height * 2
  },
  ticketItem: (index, type) => ({
    backgroundColor: type === "zendesk" ? "#9BB2D4" : "#9BB2D4",
    padding: "10px",
    color: "white",
    borderRadius: "8px",
    marginBottom: "10px",
    transition: "transform 0.2s",
    ":hover": {
      transform: "scale(1.02)",
    },
  }),
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "16px",
  },
  smallIcon: {
    width: "35px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
  },
  loadingIcon: {
    width: "30px",
    height: "30px",
  },
  loadingText: {
    marginTop: "8px",
    marginLeft: "10px",
    fontSize: "14px",
  },
  noTicketsText: {
    fontSize: "16px",
    color: "#666666",
    textAlign: "center",
    fontFamily:"Poppins",
    fontWeight:'500'
  },
};
