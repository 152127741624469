import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";


const getRandomData = () => {
  const data = [];
  for (let i = 0; i < 12; i++) {
    data.push(Math.floor(Math.random() * 201)); 
  }
  return data;
};

const ZendeskAverageBarChart = () => {
    const months = [
        'Jun 2024', 'Jul 2024', 'Aug 2024', 'Sep 2024', 'Oct 2024', 'Nov 2024',
        'Dec 2023', 'Jan 2023', 'Feb 2023', 'Mar 2023', 'Apr 2023', 'May 2023'
      ];

  const series = [
    {
      name: 'Average Tickets Per Day (Mon-Fri)',
      data: getRandomData(),
    },
    {
      name: 'Average Tickets Per Day (Mon-Sun)',
      data: getRandomData(),
    },
  ];

  const options:ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      background: '#fff', 
      events: {
        dataPointMouseEnter: function(event, chartContext, config) {
          const value = config.w.config.series[config.seriesIndex].data[config.dataPointIndex];
        
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        dataLabels: {
          position: 'top', // Show data labels on top of bars
        },
      },
    },
    colors: ['#67C6E3', '#378CE7'], // Blue and green colors for the bars
    xaxis: {
      categories: months,
    },
    legend: {
      position: 'top',
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    }
  };

  return (
    <div className="col-12 d-flex mt-3">
      <div
        className="col-12 "
        id="chart"
        style={{
          backgroundColor: "white",
          padding: "5px",
          // border: "1px solid lightgray",
          borderRadius: "10px",
        }}
      >
        <h6 className="p-4">Zendesk Average Tickets for Last 12 Months</h6>
        <Chart options={options} series={series} type="bar" height={450} />
      </div>
    </div>
  );
};

export default ZendeskAverageBarChart;
