import React, { useState, useEffect } from "react";
import "./Activity.css";

const Activities = () => {
  const [recentActs, setrecentActs] = useState([]);

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      const recentActivities = await fetch(
        `${process.env.REACT_APP_SERVER_API_LINK}/recent-activities/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      const result = await recentActivities.json();
      console.log(result, "Result Found");
      setrecentActs(result.activities);
    } catch (error) {
      console.log(error);
    }
  };
  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const activityTime = new Date(timestamp);
  
    // Calculate difference in seconds without converting to UTC
    const differenceInSeconds = (now.getTime() - activityTime.getTime()) / 1000;
  
    const minutes = Math.floor(differenceInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    // console.log(`Now: ${now}`);
    // console.log(`Activity Time: ${activityTime}`);
    // console.log(`Difference in seconds: ${differenceInSeconds}`);
    // console.log(`Days: ${days}, Hours: ${hours}, Minutes: ${minutes}`);
  
    if (days > 0) {
      return days === 1 ? `${days} day ago` : `${days} days ago`;
    } else if (hours > 0) {
      return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
    } else if (minutes > 0) {
      return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
    } else {
      return "Just now";
    }
  };
  
  
  const getEmoji = (activityType) => {
    switch (activityType) {
      case "Jira Analytics":
        return "📊";
      case "Chat History":
        return "💬";
      case "User Login":
        return "👤";
      case "Chat ID Generation":
        return "🆔";
      case "Release Notes":
        return "📝";
      default:
        return "🔔"; // default emoji for unknown activity
    }
  };
  const getBackground = (activityType) => {
    switch (activityType) {
      case "Jira Analytics":
        return "#ea4c89";
      case "Chat History":
        return "#ea4c89";
      case "User Login":
        return "#00a1ff";
      case "Chat ID Generation":
        return "#ffbe0b";
      case "Release Notes":
        return "#2ed573";
      default:
        return "#1894af";
    }
  };

  return (
    <>
      <div className="activity">
        {/* <div className="activity-title">Recent Activities</div> */}
        <h1
          style={{
            color: "#1894af",
            fontWeight: "700",
            fontSize: "14px",
            marginBottom: "20px",
            letterSpacing: "1px",
            textTransform: "uppercase",
            textAlign: "center",
            position: "relative",
            fontFamily: "Poppins",
          }}
        >
          <span
            style={{
              backgroundColor: "#ffffff",
              padding: "0 10px",
              position: "relative",
              zIndex: 1,
              fontFamily: "Poppins",
              fontSize: "14px",
            }}
          >
            Recent Activities
          </span>
          <div
            style={{
              height: "2px",
              backgroundColor: "#1894af",
              position: "absolute",
              bottom: "0",
              left: "10%",
              right: "10%",
            }}
          ></div>
        </h1>
        <div className="activity-container">
          {recentActs.length > 0 ? (
            recentActs.map((activity, index) => (
              // <div key={index} className="activity-item">
              //   <div className="activity-icon">
              //     {getEmoji(activity.activity_type)}
              //   </div>
              //   <div className="activity-content">
              //     <div className="activity-time">
              //       {getRelativeTime(activity.timestamp)}
              //     </div>
              //     <div className="activity-text">
              //       <span className="activity-user">
              //         {activity.activity_type}
              //       </span>{" "}
              //       {activity.activity_description}
              //     </div>
              //   </div>
              //   <div className="timeline"></div>
              // </div>
              <div className="activity-item" key={index}>
                <div
                  className="activity-icon"
                  style={{
                    backgroundColor: getBackground(activity.activity_type),
                  }}
                >
                  {getEmoji(activity.activity_type)}
                </div>
                <div className="activity-content">
                  <div className="activity-time">
                    {getRelativeTime(activity.timestamp)}
                  </div>
                  <div
                    className="activity-text"
                    style={{ width: "100%", textOverflow: "ellipsis" }}
                  >
                    <span className="activity-user">
                      {activity.activity_type}
                    </span>{" "}
                    {activity.activity_description}
                  </div>
                </div>
                <div className="timeline"></div>
              </div>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div
                className="spinner-grow text-secondary"
                role="status"
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "10px",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
              <div
                className="spinner-grow text-secondary"
                role="status"
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "10px",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
              <div
                className="spinner-grow text-secondary"
                role="status"
                style={{
                  width: "15px",
                  height: "15px",
                }}
              >
                <span className="sr-only">Loading...</span>
              </div>
              {/* <img
src={Loader}
alt="Loading..."
style={{ width: "40px", height: "40px" }}
/> */}
            </div>
          )}
          {/* <div className="activity-item">
            <div className="activity-icon task">👤</div>
            <div className="activity-content">
              <div className="activity-time">42 Minutes Ago</div>
              <div className="activity-text">
                <span className="activity-user">John Doe</span> Registered a New
                Account
              </div>
            </div>
            <div className="timeline"></div>
          </div>
          <div className="activity-item">
            <div className="activity-icon deal">✔️</div>
            <div className="activity-content">
              <div className="activity-time">5 Hours Ago</div>
              <div className="activity-text">
                <span className="activity-user">Alex</span> Resolved Ticket
                #12345
              </div>
            </div>
            <div className="timeline"></div>
          </div>
          <div className="activity-item">
            <div className="activity-icon article">💬</div>
            <div className="activity-content">
              <div className="activity-time">12 hours Ago</div>
              <div className="activity-text">
                <span className="activity-user">Sarah</span> Started Chat with
                Ally Assistant
              </div>
            </div>
            <div className="timeline"></div>
          </div>
          <div className="activity-item">
            <div className="activity-icon dock">✏️</div>
            <div className="activity-content">
              <div className="activity-time">1 day Ago</div>
              <div className="activity-text">
                <span className="activity-user">User</span> Updated Profile
                Information
              </div>
            </div>
            <div className="timeline"></div>
          </div>
          <div className="activity-item">
            <div className="activity-icon comment">🛠️</div>
            <div className="activity-content">
              <div className="activity-time">2 day Ago</div>
              <div className="activity-text">
                <span className="activity-user">System</span> Completed
                Maintenance
              </div>
            </div>
          </div> */}
        </div>{" "}
      </div>
    </>
  );
};
export default Activities;
