import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import "../src/assets/css/stylesheet.css";
import { Navbar } from "./components/NavBar/Navbar";
import { Homepage } from "./components/Homepage/Homepage";
import "boxicons/css/boxicons.min.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Uploadfile } from "./components/Uploadfile/Uploadfile";
import { Footer } from "./components/Footer/Footer";
import { Login } from "./components/AuthComponents/Login";
import TypeWriter from "./components/Chatpage/TypeWriter.tsx";
import PersonaLandingPage from "./components/Personas/PersonaLandingPage.js";
import ContainerExampleContainer from "./components/Chatpage/JSONHolder.tsx";
import PrivateRoutes from "./PrivateRoutes.js";
import { Chat } from "./components/Chatpage/Chat.tsx";
import { DashboardTest } from "./components/Dash/DashboardTest.tsx";
import { Dash } from "./components/Dashboard/Dash.tsx";
import Profile from "./components/Dash/Profile.tsx";
import Settings from "./components/Dash/Settings.tsx";
import { NewChat } from "./components/Chatpage/NewChat.tsx";
import NewUI from "./components/Chatpage/NewUI.tsx";
import { AIChat } from "./components/Chatpage/AIChat.tsx";
import { Talktodata } from "./components/Talktodata/Talktodata.tsx";

const BeforeUnloadContext = React.createContext();

const withLocation = (Component) => (props) => {
  const location = useLocation();
  return <Component {...props} location={location} />;
};

function AppRouter({ location }) {
  const shouldDisplayFooter = () => {
    const pathsWithoutFooter = [
      "/chat",
      "/landingpage",
      "/dashboard",
      "/dashboard/chat",
      "/dashboard/homepage",
      "/dashboard/AiChat",
      "/dashboard/profile",
      "/dashboard/settings",
      "/dashboard/notifications",
      "/dashboards",
      "/dashboards/new",
      "/dashboard/talktodata",
    ];
    return !pathsWithoutFooter.includes(location.pathname);
  };
  const shouldDisplayHeader = () => {
    const pathsWithoutFooter = [
      "/landingpage",
      "/dashboard/chat",
      "/chat",
      "/uploadfile",
      "/dashboard/homepage",
      "/dashboard/profile",
      "/dashboard/settings",
      "/dashboard/notifications",
      "/dashboards",
      "/dashboards/new",
      "/dashboard/AiChat",
      "/dashboard/talktodata",

    ];
    return !pathsWithoutFooter.includes(location.pathname);
  };

  return (
    <div>
      {shouldDisplayHeader() && <Navbar />}
      <div>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/landingpage" element={<PersonaLandingPage />} />

            <Route path="/dashboard" element={<DashboardTest />}>
              <Route path="/dashboard/chat" element={<Chat />} />
              <Route path="/dashboard/homepage" element={<Dash />} />
              <Route path="/dashboard/profile" element={<Profile />} />
              <Route path="/dashboard/settings" element={<Settings />} />
              <Route path="/dashboard/AiChat" element={<AIChat />} />
              <Route path="/dashboard/talktodata" element={<Talktodata />} />
            </Route>
          </Route>
          <Route path="/dashboards" element={<NewChat />}>
            <Route path="/dashboards/new" element={<NewUI />} />
            <Route path="/dashboards/chat" element={<Chat />} />
            <Route path="/dashboards/homepage" element={<Dash />} />
            <Route path="/dashboards/profile" element={<Profile />} />
            <Route path="/dashboards/settings" element={<Settings />} />
          </Route>

          <Route path="/" exact element={<Homepage />} />
          <Route path="/dashboards" element={<NewChat />} />
          <Route path="/login" element={<Login />} />
          <Route path="/uploadfile" element={<Uploadfile />} />

          <Route path="/lps" element={<ContainerExampleContainer />} />
        </Routes>
      </div>
      {shouldDisplayFooter() && <Footer />}
    </div>
  );
}

const AppWithLocation = withLocation(AppRouter);

function App() {
  // const [isPerformingAction, setIsPerformingAction] = useState(false);

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     if (!isPerformingAction) {
  //       e.preventDefault();
  //       e.returnValue = "Are you sure you want to leave?";
  //       window.addEventListener("unload", () => {
  //         sessionStorage.clear();
  //       });
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isPerformingAction]);
  const [isPerformingAction, setIsPerformingAction] = useState(false);

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     if (isPerformingAction) return;
  //     e.preventDefault();
  //     e.returnValue = "Are you sure you want to leave?";
  //     window.addEventListener("unload", () => {
       
  //     });
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isPerformingAction]);

  const contextValue = {
    setIsPerformingAction,
  };

  return (
    <BeforeUnloadContext.Provider value={contextValue}>
      <Router>
        <AppWithLocation />
      </Router>
    </BeforeUnloadContext.Provider>
  );
}

export { BeforeUnloadContext };
export default App;
