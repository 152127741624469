import React, { useState, useEffect } from "react";
import "./NewChat.css";
import allylogo from "../../assets/images/allylogo.png";
import user from "../../assets/images/chatuserimage.png";
import { Icon } from "@iconify/react";
import { BrowserRouter as Router, Outlet, useLocation } from "react-router-dom";
import { BrowserRouter as Route, NavLink } from "react-router-dom";

export const NewChat = () => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState("");
  

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  return (
    <>
      <div id="layout-wrapper">
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            <div className="navbar-brand-box">
              <a className="logo">
                <img src={allylogo} />
              </a>
            </div>

            <div id="sidebar-menu" className="sidebar-menu">
              <ul
                className="metismenu list-unstyled active-menu"
                id="side-menu"
              >
                <div className="menu-items-wrapper">
                  <li className="menu-title">Menu</li>
                  <li>
                    <NavLink
                      to="/dashboards/new"
                      className="waves-effect"
                      style={{
                        color:
                          activeRoute === "/dashboards/new"
                            ? "#0047AB"
                            : "black",
                        backgroundColor:
                          activeRoute === "/dashboards/new"
                            ? "#F0FFFF"
                            : "white",
                        borderRight:
                          activeRoute === "/dashboards/new"
                            ? "4px solid #A7C7E7"
                            : "4px solid white",
                      }}
                    >
                      <i className="bx bx-home-smile"></i>
                      <span style={{ fontWeight: "lighter" }}>Dashboard</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboards/chat"
                      className="waves-effect"
                      style={{
                        color:
                          activeRoute === "/dashboards/chat"
                            ? "#0047AB"
                            : "black",
                        backgroundColor:
                          activeRoute === "/dashboards/chat"
                            ? "#F0FFFF"
                            : "white",
                        borderRight:
                          activeRoute === "/dashboards/chat"
                            ? "4px solid #A7C7E7"
                            : "4px solid white",
                      }}
                    >
                      <i className="bx bx-chat"></i>
                      <span style={{ fontWeight: "lighter" }}>Chat</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboards/profile"
                      className="waves-effect"
                      style={{
                        color:
                          activeRoute === "/dashboards/profile"
                            ? "#0047AB"
                            : "black",
                        backgroundColor:
                          activeRoute === "/dashboards/profile"
                            ? "#F0FFFF"
                            : "white",
                        borderRight:
                          activeRoute === "/dashboards/profile"
                            ? "4px solid #A7C7E7"
                            : "4px solid white",
                      }}
                    >
                      <i className="bx bx-user"></i>
                      <span style={{ fontWeight: "lighter" }}>Profile</span>
                    </NavLink>
                  </li>
                </div>
                <div className="logout-wrapper">
                  <li>
                    <NavLink
                      to="/login"
                      className="waves-effect"
                      style={{
                        color: activeRoute === "/login" ? "#0047AB" : "black",
                        backgroundColor:
                          activeRoute === "" ? "#F0FFFF" : "white",
                        borderRight:
                          activeRoute === ""
                            ? "4px solid #A7C7E7"
                            : "4px solid white",
                      }}
                    >
                      <i className="bx bx-log-out"></i>
                      <span style={{ fontWeight: "lighter" }}>Logout</span>
                    </NavLink>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div className="main-content">
          <header id="page-topbar">
            <div className="navbar-header">
              <div className="d-flex align-items-left">
                <button
                  type="button"
                  className="btn btn-sm mr-2 d-lg-none px-3 font-size-16 header-item waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars"></i>
                </button>
              </div>
              <div className="search-container">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search..."
                />
                <i className="bx bx-search search-icon"></i>
              </div>
              <div className="profile">
                <div className="col-md-2 mr-1">
                  <img src={user} alt="Profile" className="profile-image" />
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-10">
                  <span className="profile-name">
                    {sessionStorage.getItem("userName")}
                  </span>
                  <br />
                  <span className="profile-name">
                    {sessionStorage.getItem("email")}
                  </span>
                </div>
              </div>
            </div>
          </header>
          <Outlet />
        </div>
      </div>
    </>
  );
};
