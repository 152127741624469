import React from "react";
import logo from "../../assets/images/allylogo.png";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathName = location.pathname;
  const isLoggedIn = sessionStorage.getItem("token");

  const removeLocal = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const submit = () => {
    navigate("/login");
  };

  const goTo = () => {
    navigate("/");
  };

  return (
    <div>
      <div id="navbar">
        <div className="container-fluid">
          <nav className="navbar navbar-light bg-light justify-content-between">
            <img
              src={logo}
              alt="logo"
              style={{ width: 100, cursor: "pointer" }}
              onClick={goTo}
            />

            {pathName !== "/login" && ( 
              <>
                {!isLoggedIn ? (
                  <form className="form-inline">
                    <button
                      className="btn btn-outline-success my-2 my-sm-0"
                      type="button" 
                      onClick={submit}
                      style={{
                        backgroundColor: "white",
                        border: "1px solid grey",
                        color: "black",
                      }}
                    >
                      Login
                    </button>
                  </form>
                ) : (
                  <form className="form-inline d-flex">
                    <Link to="/login">
                      <button
                        className="btn my-2 my-sm-0"
                        type="button" // Changed to type="button" to prevent form submission
                        onClick={removeLocal}
                        style={{
                          backgroundColor: "white",
                          border: "1px solid grey",
                        }}
                      >
                        Logout
                      </button>
                    </Link>
                  </form>
                )}
              </>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};
