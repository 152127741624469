import { Navigate,Outlet } from "react-router-dom";

const useAuth=()=>{
    const user = sessionStorage.getItem("token")
    return user 
}

const PrivateRoutes = () =>{

    const isAuth = useAuth();
    return isAuth ? <Outlet/> : <Navigate to={'/login'}/>
}

export default PrivateRoutes;