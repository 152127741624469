import React, { useState, useEffect,useContext } from "react";
import jira from "../../assets/images/jira.png";
import zendesk from "../../assets/images/zendesk.png";
import loading from "../../assets/images/loading-unscreen.gif";
import Down from "../../assets/images/down.png";
import Up from "../../assets/images/arrow-up.png";
import './T1support.css';
import { AIChat } from "../Chatpage/AIChat.tsx";
// import {UserContext} from "./UserContext"

export const T1support = ({
  data,
  extraData,
  divData,
  imageData,
  relevantTickets,
  zendeskData,
}) => {
  let [ticket, setTickets] = useState([]);
  let [relevantLinks, setrelevantLinks] = useState([]);
  const [load, setLoad] = useState(false);
  const [jiraImage, setJiraImage] = useState(Up);
  const [zendeskImage, setZendeskImage] = useState(Up);

  const [showJiraDiv, setShowJiraDiv] = useState(true);
  const [showZendeskDiv, setShowZendeskDiv] = useState(true);
  let [zendeskTicket, setZendeskTicket] = useState([]);
  // const user = useContext(UserContext);
  // console.log(data, "jiraETIc");
  // console.log(extraData, "datsa");
  // console.log(divData, "divData");
  // console.log(imageData, "imageData");
  // console.warn(zendeskData, "Zendesk");

  const toggleJiraImage = () => {
    setJiraImage(jiraImage === Up ? Down : Up);
    setShowJiraDiv(showJiraDiv === true ? false : true);
  };
  const toggleZendeskImage = () => {
    setZendeskImage(zendeskImage === Up ? Down : Up);
    setShowZendeskDiv(showZendeskDiv === false ? true : false);
  };
  useEffect(() => {
    if (data) {
      // console.log(ticket, "Ticket BEfore");
      setTickets((ticket = data.jira_tickets));
      setrelevantLinks((relevantLinks = data.relevantLinks));
      // console.log(relevantLinks,"Relevant Linkskskks")
      // console.log(ticket, "Ticket After");
      setLoad(false);
      if (imageData && divData === true) {
        setJiraImage(jiraImage === Up);
        setShowJiraDiv(showJiraDiv === true);
      }
    } else {
      setLoad(true);
    }
    if (zendeskData) {
      // console.log(zendeskTicket, "Zendesk Ticket BEfore");
      setZendeskTicket((zendeskTicket = zendeskData.related_tickets));
      // console.log(zendeskTicket, "Zendesk Ticket After");

      setLoad(false);
    }
  }, [data, zendeskData]);

  useEffect(() => {
    setLoad(extraData);
  }, [extraData]);
  useEffect(() => {
    setShowJiraDiv(divData);
  }, [divData]);
  // const functionCall = () =>{
  //   setImage(image=Up)
  //   console.log("hello");
  // }
  return (
    <div>
      {/*  */} <br />
      <div id="newacc">
        <div>
          <h6 className="" style={{color:"#1894af",fontFamily:'Poppins',fontWeight:'600',marginLeft:'2px'}}>Relevant Issues</h6>
          <div
            style={{
              height: "100vh",
              overflowY: "auto",
              boxSizing: "border-box",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <div className="cardHeader" onClick={toggleJiraImage}>
              <img src={jira} alt="Jira" className="icon" />
              <span className="ticketText">Jira</span>
              <a href={data?.all_tickets_url || ""} className="numbers" target="_blank">{ticket?.length || 0}</a>

              <img
                src={jiraImage}
                alt="Toggle Arrow"
                className="toggleIcon"
              />
            </div>
            {!showJiraDiv && (
              <div className="ticketsDiv">
                {ticket && ticket.length > 0 ? (
                  ticket.map((item, index) => (
                    <div key={item.key} className="ticketItem">
                      <a href={item.url} target="_blank" className="link">
                        <img src={jira} alt="" className="smallicon" />{" "}
                        &nbsp;
                        <strong style={{ marginTop: "5px" }}>
                          {item.key}
                        </strong>{" "}
                        <br />
                        <div className="summary">{item.summary}</div>
                      </a>
                    </div>
                  ))
                ) : load ? (
                  <div className="loadingContainer">
                    <img src={loading} alt="" className="loadingIcon"/>
                    <p className="loadingText">Fetching Related Issues</p>
                  </div>
                ) : (
                  <p className="noTicketsText">
                    No Relevant Issues Found !!
                  </p>
                )}
              </div>
            )}

            <div className="cardHeader" onClick={toggleZendeskImage}>
              <img src={zendesk} alt="Zendesk" className="icon" />
              <span className="ticketText">Zendesk</span>
              <a href={"https://www.zendesk.com/in/#georedirect"} className="numbers" target="_blank">{zendeskTicket?.length || 0}</a>
              <img
                src={zendeskImage}
                alt="Toggle Arrow"
                className="toggleIcon"
              />
            </div>
            {showZendeskDiv && (
              <div className="ticketsDiv">
                {zendeskTicket && zendeskTicket.length > 0 ? (
                  zendeskTicket.map((item, index) => (
                    <div
                      key={item.id}
                      className="ticketItem"
                    >
                      <a href={item.url} target="_blank" className="link">
                        <img src={zendesk} alt="" className="smallicon" />{" "}
                        &nbsp;
                        <strong>{item.id}</strong>
                        <div className="summary">{item.description}</div>
                      </a>
                    </div>
                  ))
                ) : load ? (
                  <div style={styles.loadingContainer}>
                    <img src={loading} alt="" style={styles.loadingIcon} />
                    <p className="loadingText">Fetching Related Issues</p>
                  </div>
                ) : (
                  <p className="noTicketsText">
                    No Relevant Issues Found !!
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#ffffff",
    border:'1px solid lightgray',
    padding: "10px 10px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "15px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    msOverflowStyle: "none",
    color:'#1894af',
    scrollbarWidth: "none",
    "&::hover": {
      backgroundColor:'blue'
    }
  //   &:hover {
  //     color: #15325b;
  //     transition: 0.5s;
  // }
   
  },
  icon: {
    width: "30px",
    
  },
  ticketText: {
    fontSize: "14px",
    fontWeight: "600",
    flexGrow: 1,
    marginLeft: "15px",
    color:"black"
    
  },
  numbers: {
    // fontSize: "18px",
    // fontWeight: "bold",
    // color: "#333",
    width: "30px",
    height: "30px" /* Added height to ensure circular shape */,
    lineHeight: "30px",
    borderRadius: "50%",
    textAlign: "center",
    backgroundColor: "#1894af",
    margin: "0 10px" /* Adjust margin to keep elements aligned */,
    color: "white",
  },
  toggleIcon: {
    width: "10px",
    height: "10px",
  },
  ticketsDiv: {
    // padding: "15px 20px",
    // backgroundColor: "#ffffff",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    borderRadius: "10px",
    marginBottom: "15px",
    maxHeight: "317px",
    overflowY: "auto",
  },
  summary: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    color:'black',
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2, // Limit to 2 lines
    lineHeight: "1.5em", // Adjust line height as needed
    maxHeight: "3em",
    padding: "2px", // Line height * 2
  },
  ticketItem: (index, type) => ({
    // backgroundColor: type === "zendesk" ? "#0E2238" : "#0E2238",
    padding: "10px",
    color: "black",
    borderRadius: "8px",
    marginBottom: "10px",
    transition: "transform 0.2s",
    ":hover": {
      transform: "scale(1.02)",
    },
  }),
  link: {
    textDecoration: "none",
    color: "black",
    marginTop:'15px',
    fontSize: "14px",
  },
  smallIcon: {
    width: "16px",
    marginBottom:'5px'
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
  },
  loadingIcon: {
    width: "30px",
    height: "30px",
  },
  loadingText: {
    marginTop: "8px",
    marginLeft: "10px",
    fontSize: "16px",
  },
  noTicketsText: {
    fontSize: "14px",
    color: "black",
    textAlign: "center",
    fontFamily:"Poppins",
    fontWeight:"300"
  },
};
