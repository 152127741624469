import React, { useState, useEffect } from "react";

const TypeWriter = ({ text, speed }: { text: string; speed: number }) => {
  const [lines, setLines] = useState<string[]>([]);
  const [isTyping, setIsTyping] = useState(true);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    console.log(text,"String Text Received")

    let linesArray = text.split("\n");
 
    if(text === undefined ){
      window.alert("Error in Fetching data")
    }
    console.log(linesArray,"Liens Array")
    let currentLine = 0;
    let currentChar = 0;
    const interval = setInterval(() => {
      if (currentLine < linesArray.length) {
        
        const line = linesArray[currentLine];
        setLines((prevLines) => {
          const newLines = [...prevLines];
          newLines[currentLine] = line.substring(0, currentChar);
          return newLines;
        });
        if (currentChar < line.length) {
          currentChar++;
        } else {
          currentChar = 0;
          currentLine++;
        }
      } else {
        setIsTyping(false);
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <div>
      {lines.map((line, index) => (
        <div key={index}>{line} {showCursor}</div>
      ))}
      {isTyping}
    </div>
  );
};

export default TypeWriter;
