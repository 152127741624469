import React from "react";
import { useState, useEffect,createContext,useContext } from "react";
import "./AIChat.css";
import AllyLogo from "../../assets/images/allylogo.png";
import { formatDistanceToNow } from "date-fns";
import { T1support } from "../Personas/T1support";
import { Chat } from "./Chat.tsx";

interface IMessage {
  id: string;
  name: string;
  type: string;
  output: string;
  createdAt: string;
  relevantLinks: string[];
}

export const AIChat = () => {
  const [user, setUser] = useState("Hello");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  let [chatIds, setChatId] = useState("");
  const [showLoader, setShowLoader] = useState(false);



  const [suggestion, setSuggestion] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [hideSuggestions, setHideSuggestions] = useState(false);
  const [showChats, setShowChats] = useState(false);
  const [chats, setChats] = useState<IMessage[]>([]);
  const [uniqueChatsLast15Days, setUniqueChatsLast15Days] = useState({});
  const [filteredChats, setFilteredChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatScreen, setChatScreen] = useState(false);
  let [inputValue, setInputValue] = useState("");
  let [inputData, setInputData] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [apiData, setApiData] = useState<any>(null);
  const [apiData1, setApiData1] = useState<any>(null);
  const [apiData2, setApiData2] = useState<any>(null);
  let [currentChatId, setCurrentChatId] = useState("");


  useEffect(() => {
    setChatId((chatIds = currentChatId));
    console.log(currentChatId, "CurrentChaDDD");

    console.log(chatIds, "ChatIds INVOKED");
  }, [messages]);

  const names = [
    "Staff Scheduling",
    "Invoicing Refunds",
    "Jira Analytics",
    "Pricing Rules",
    "User Permissions",
    "Service Reservations",
    "Email System",
    "Account Billing",
    "Group Permissions",
    "Invoice Adjustments",
    "Service Configuration",
    "User Log-in",
  ];
  const getRandomNames = (arr, num) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const chatss = [
    {
      id: 1,
      user: "User1",
      message: "Tell me about partner api's ?",
      date: "2 days ago",
    },
    {
      id: 2,
      user: "User2",
      message: "Explain me about customer portal?",
      date: "5 days ago",
    },
    {
      id: 3,
      user: "User1",
      message: "DNS Server routes are not working fine ?",
      date: "7 days ago",
    },
  ];

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const [currentNames, setCurrentNames] = useState(getRandomNames(names, 3));

  const refreshNames = () => {
    setCurrentNames(getRandomNames(names, 3));
  };
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (!term) {
      setFilteredChats([]);
      return;
    }

    const filterChats = (chats) => {
      return Object.entries(chats)
        .filter(([chatId, chat]) =>
          chat.firstWords.toLowerCase().includes(term)
        )
        .reduce((acc, [chatId, chat]) => ({ ...acc, [chatId]: chat }), {});
    };

    const filtered = filterChats(uniqueChatsLast15Days);
    setFilteredChats(filtered);
  };

  const listChats = () => {
    setShowChats(!showChats);
  };
  const [showButton, setShowButton] = useState(false);

  const performAction = () => {
    try {
      if (suggestion === "Hide Suggestions") {
        setHideSuggestions(true);
        console.log("true");
      }
    } catch {
      console.log("error");
    }
  };
  const [showSubmit, setShowSubmit] = useState(false);

  const handleInput = (e) => {
    if (e.target.value.length > 0) {
      setShowSubmit(true);
    } else {
      setShowSubmit(false);
    }
  };
  const enablechatscreen = () => {};
  useEffect(() => {
    getChats();
  }, []);
  const getChats = async () => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const userEmail = sessionStorage.getItem("email");

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/chat-history/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data.MetaData");
      setChats(data.MetaData);
      const currentDate = new Date();
      const uniqueChatsLast15Days = {};

      Object.entries(data.MetaData).forEach(([chatId, chats]) => {
        chats.forEach((chat) => {
          const chatDate = chat.resp_time.split("T")[0];
          const user = chat.user;
          const words = chat.response.split(" ");
          const firstWords =
            user === userEmail ? words.slice(0, 8).join(" ") : null;

          const diffInDays = Math.floor(
            (currentDate - new Date(chatDate)) / (1000 * 60 * 60 * 24)
          );
          if (diffInDays <= 15 && firstWords && words.length >= 7) {
            uniqueChatsLast15Days[chatId] = {
              firstWords,
              createdAt: chat.resp_time,
            };
          }
        });
      });

      const sortChatsByDate = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).sort((a, b) => {
            const timestampA = new Date(a[1].createdAt).getTime();
            const timestampB = new Date(b[1].createdAt).getTime();
            return timestampB - timestampA; // Sort in descending order
          })
        );
      };

      const sortedChats = sortChatsByDate(uniqueChatsLast15Days);
      setUniqueChatsLast15Days(sortedChats);
      setLoading(false);
      console.warn(sortedChats, "Last 15 Days");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  const chatsToRender = searchTerm ? filteredChats : uniqueChatsLast15Days;
  const sortedChats = Object.entries(chatsToRender)
    .sort((a, b) => new Date(b[1].createdAt) - new Date(a[1].createdAt))
    .slice(0, 2);
  const handleSendMessage = async () => {
    setChatScreen(true);

    const content = inputValue.trim();
    setInputData((inputData = content));
    console.warn(inputData, "INPUTDataINPUTDATA");

    console.log(inputData, "INPUT DATA");
    setInputValue("");
    setApiData1([]);
    setApiData2([]);
    setShowLoader(true);

    try {
      const token = sessionStorage.getItem("token");
      console.log(token, "INside");
      let link = "";
      let indexName = "";
      if (sessionStorage.getItem("userRole") === "Ally-User") {
        link = "query-file";
      } else if (sessionStorage.getItem("userRole") === "Ally-Support") {
        link = "support-query-file";
      } else {
        link = "support-query-file";
      }
      console.log(`${process.env.REACT_APP_LOCALHOST_API_LINK}/${link}`);
      console.log(indexName);
      console.log(link);
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/${link}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: inputData,
            chatId: chatIds,
          }),
        }
      );
      console.log(response, "Response");
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      console.log(data, "Data with Relevant Links");
      setApiData(data);
      const res = data.llmQueryResponse;
      console.log(res, "Res");
      const relevantLinkss = data.relevantLinks || [];

      if (res) {
        getChats();
      }
      console.log(messages, "Messages Recieved In the Set Of Messages");
      console.log(`${process.env.REACT_APP_SERVER_API_LINK}/jiraQuery`, "Jira");
      const response2 = await fetch(
        `${process.env.REACT_APP_SERVER_API_LINK}/jira-query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            question: inputData,
          }),
        }
      );
      const data2 = await response2.json();
      const response3 = await fetch(
        `${process.env.REACT_APP_SERVER_API_LINK}/zendesk-query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            user_query: inputData,
          }),
        }
      );
      const data3 = await response3.json();

      console.warn(messages, "Messchin");
      console.log(data3, "Response 3");
      if (data2) {
        setApiData1(data2);
        console.log(inputData, "Input Value Is Set");
      }
      if (data3) {
        setApiData2(data3);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setInputValue("");
    }
  };
  return (
    <>
      <div className="container-fluid aichatdiv">
        {loading ? (
          <>

            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              {!chatScreen && (
                <>
                  {" "}
                  {!showChats && (
                    <>
                      <div className="col-12  mt-5">
                        <h4 className="text-center">Ally Assistant</h4>
                        <h1
                          className="text-center mb-4 mt-4"
                          style={{
                            color: "var(--text-clr)",
                            fontWeight: "500",
                          }}
                        >
                          Good evening, Support User
                        </h1>
                      </div>

                      <form
                        action=""
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSendMessage();
                        }}
                      >
                        <div className="col-12 d-flex justify-content-center container">
                          <textarea
                            className="mainArea"
                            placeholder="How can Ally assist you with today?"
                            onInput={handleInput}
                            onChange={(e) =>
                              setInputValue((inputValue = e.target.value))
                            }
                            value={inputValue}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                              }
                            }}
                          ></textarea>
                          {showSubmit && (
                            <>
                              {" "}
                              <span
                                className="submit"
                                onClick={handleSendMessage}
                              >
                                <i
                                  className="bx bx-up-arrow-alt bx-sm"
                                  style={{ margin: "5px", color: "white" }}
                                ></i>
                              </span>
                            </>
                          )}
                        </div>
                      </form>
                      {!hideSuggestions && (
                        <>
                          <div className="d-flex justify-content-center">
                            <div className="mainArea1 p-2">
                              <div className="col-12 p-0 m-0 d-flex justify-content-between">
                                <span className="tryonetext datas">
                                  Try one of our suggestions!
                                </span>
                                <div>
                                  <span className="docstext">
                                    <div
                                      style={{
                                        display: "inline-block",
                                        width: "5px",
                                      }}
                                    ></div>
                                    <i
                                      className="bx bx-refresh bx-sm"
                                      style={{
                                        cursor: "pointer",
                                        color: "var(--primary-color)",
                                      }}
                                      onClick={refreshNames}
                                      onMouseEnter={() => {
                                        setShowSuggestions(true);
                                        setSuggestion("Reload Suggestions");
                                      }}
                                      onMouseLeave={() => {
                                        setShowSuggestions(false);
                                        setSuggestion("");
                                      }}
                                    ></i>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        width: "5px",
                                      }}
                                    ></div>
                                    <i
                                      className="bx bx-x bx-sm closeicon"
                                      style={{
                                        cursor: "pointer",
                                        color: "var(--primary-color)",
                                      }}
                                      onMouseEnter={() => {
                                        setShowSuggestions(true);
                                        setSuggestion("Hide Suggestions");
                                      }}
                                      onMouseLeave={() => {
                                        setShowSuggestions(false);
                                        setSuggestion("");
                                      }}
                                      onClick={performAction}
                                    ></i>
                                  </span>
                                  <span
                                    className="spanclass"
                                    style={{
                                      position: "absolute",
                                      left: "68.8%",
                                      top: "38%",
                                      borderRadius: "10px",
                                      padding: "3px",
                                      display: showSuggestions
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    {suggestion}
                                  </span>
                                </div>
                              </div>
                              <div className="row p-0 m-0 mt-3 showdata">
                                {currentNames.map((name, index) => (
                                  <div
                                    key={index}
                                    className="col-md-4 mb-2"
                                    style={{ position: "relative" }}
                                  >
                                    <div
                                      style={{
                                        padding: "5px",
                                        borderRadius: "10px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        backgroundColor:
                                          "var(--secondary-color)",
                                        color: "var(--background-color)",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <span className="datas">{name}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="d-flex justify-content-center ">
                        <div className="chatsdiv d-flex p-3 justify-content-between">
                          <div className="d-flex">
                            <i
                              className="bx bx-message-dots bx-xs"
                              style={{ color: "#1c9aaa" }}
                            ></i>
                            <div
                              style={{ display: "inline-block", width: "10px" }}
                            ></div>
                            <h6 style={{ fontSize: "12px", color: "gray" }}>
                              Your recent chats{" "}
                            </h6>
                            <i
                              className={`bx ${
                                isExpanded ? "bx-chevron-up" : "bx-chevron-down"
                              } bx-xs`}
                              style={{
                                cursor: "pointer",
                                marginLeft: "8px",
                                marginTop: "-2px",
                              }}
                              onClick={handleToggle}
                            ></i>
                            {!isExpanded && (
                              <span
                                className="show-chats-text showChatsDiv"
                                onClick={handleToggle}
                                style={{
                                  cursor: "pointer",
                                  color: "#0d768b",
                                  fontSize: "10px",
                                }}
                              >
                                Show chats
                              </span>
                            )}
                          </div>
                          <div className="d-flex">
                            <h6
                              style={{
                                fontSize: "12px",
                                color: "gray",
                                cursor: "pointer",
                              }}
                              onClick={listChats}
                            >
                              View all
                            </h6>
                            <div
                              style={{ display: "inline-block", width: "5px" }}
                            ></div>

                            <i
                              className="bx bx-right-arrow-alt"
                              style={{ color: "#1c9aaa", cursor: "pointer" }}
                              onClick={listChats}
                            ></i>
                          </div>
                        </div>
                      </div>
                      {isExpanded && (
                        <>
                          <div className="d-flex justify-content-center">
                            <div className="chatsdiv d-flex p-3 justify-content-between">
                              <div className="row col-12 d-flex">
                                {sortedChats.map(([chatId, chat]) => (
                                  <div
                                    className="col-md-4 m-1 p-3 chatshowcase"
                                    style={{
                                      border: "1px solid #F6F1F0",
                                      borderRadius: "20px",
                                    }}
                                  >
                                    <span>
                                      {" "}
                                      <i
                                        className="bx bx-message-dots bx-sm"
                                        style={{ color: "#1c9aaa" }}
                                      ></i>
                                      <h6
                                        className=""
                                        style={{
                                          fontSize: "14px",
                                          color: "gray",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {chat.firstWords}
                                      </h6>
                                      <h6
                                        className=""
                                        style={{
                                          fontSize: "10px",
                                          color: "gray",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {formatDistanceToNow(
                                          new Date(chat.createdAt),
                                          {
                                            addSuffix: true,
                                          }
                                        )}
                                      </h6>
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {showChats && (
                    <>
                      <div className="col-12 d-flex justify-content-end">
                        <div className="button-container" onClick={listChats}>
                          <button className="start-chat-button">
                            <i
                              className="bx bx-plus-circle bx-sm"
                              style={{ marginRight: "15px" }}
                            ></i>
                            <span className="button-text">Start New Chat</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <h3
                          className="text-center mb-4 mt-4"
                          style={{
                            color: "var(--text-clr)",
                            fontWeight: "500",
                          }}
                        >
                          Your Chats
                        </h3>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="inputfieldcontainer">
                          <i className="bx bx-search search-icon"></i>
                          <input
                            type="text"
                            className="newinputfield"
                            placeholder="Search for chats..."
                            value={searchTerm}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="chat-container">
                          <div className="previouschatsdiv">
                            {Object.entries(chatsToRender).map(
                              ([chatId, chat]) => (
                                <div key={chatId} className="chat-item">
                                  <div className="chat-message">
                                    {chat.firstWords}
                                  </div>
                                  <div className="chattime">
                                    {formatDistanceToNow(
                                      new Date(chat.createdAt),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {chatScreen && (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100vh" }}
                  >
                    <div className="spinner-border text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  <T1support
                   data={apiData1}
                   zendeskData={apiData2}      
                   extraData={undefined}
                   divData={undefined}
                   imageData={undefined}
                   relevantTickets={undefined}
                  />
                </>
              )}
            
              

              
            </div>
          </>
        )}
      </div>
    </>
  );
};
