import React, { useState } from "react";
import Chart from "react-apexcharts";
// import "./ChartComponent.css";

const TicketsHeatMap: React.FC = () => {
  const statuses1 = [
    "30 Days",
    "15 Days",
    "10 Days",
    "7 Days",
  ];

  const generateData = () => {
    const dates = [
      "2024-07-21",
      "2024-07-22",
      "2024-07-23",
      "2024-07-24",
      "2024-07-25",
      "2024-07-26",
      "2024-07-27",
      "2024-07-28",
      "2024-07-29",
      "2024-07-30",
      "2024-07-31",
      "2024-08-01",
      "2024-08-02",
      "2024-08-03",
    ];

    const statuses = ["Blocker", "Critical", "Major", "Trivial", "Minor"];

    const data = statuses.map((status) => {
      return {
        name: status,
        data: dates.map((date) => {
          return {
            x: date,
            y: Math.floor(Math.random() * 26),
          };
        }),
      };
    });

    return data;
  };

  const [selectedStatus, setSelectedStatus] = useState(statuses1[0]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const getColorRange = () => {
    switch (selectedStatus) {
      case "30 Days":
        return [
          '#FDEDED' ,
          '#FBD6D6',
          '#F9C0C0',
          '#F8A3A3',
          '#F57F7F',
          '#F33F36',
          '#E60012',

        ];
      case "15 Days":
        return [
          '#FDEDED' ,
          '#FBD6D6',
          '#F9C0C0',
          '#F8A3A3',
          '#F57F7F',
          '#F33F36',
          '#E60012', 
        ];
      case "10 Days":
        return [
          '#FDEDED' ,
          '#FBD6D6',
          '#F9C0C0',
          '#F8A3A3',
          '#F57F7F',
          '#F33F36',
          '#E60012', 
        ];
      case "7 Days":
        return [
          '#FDEDED' ,
          '#FBD6D6',
          '#F9C0C0',
          '#F8A3A3',
          '#F57F7F',
          '#F33F36',
          '#E60012', 
        ];
    
      default:
        return [
          '#FDEDED' ,
          '#FBD6D6',
          '#F9C0C0',
          '#F8A3A3',
          '#F57F7F',
          '#F33F36',
          '#E60012', 
        ];
    }
  };

  const colorRange = getColorRange().map((color, index) => ({
    from: index * 4,
    to: (index + 1) * 4,
    color,
  }));

  const series = generateData();

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.6,
        colorScale: {
          ranges: colorRange,
        },
        distributed: true,
        enableShades: false,
        borderColor: "transparent", // Ensure no borders between cells
        radius: 0, // Remove rounded corners
      },
    },
    stroke: {
      show: false, // Hide any stroke around the heatmap cells
    },
    grid: {
      show: false, // Hide grid lines
      borderColor: "transparent", // Remove grid borders
      position: 'back', // Ensure grid lines are in the background
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
    },
    yaxis: {
      axisBorder: {
        show: false, // Hide y-axis border
      },
      axisTicks: {
        show: false, // Hide y-axis ticks
      },
    },
    title: {
      // text: "Zendesk Tickets HeatMap",
      align: "center",
      style: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    legend: {
      show: true,
      position: "right",
      offsetY: 40,

      height: 230,
      labels: {
        useSeriesColors: false,
        colors: '#808080', // Set legend label color to gray
      },
    },
  };

  return (
    <div className="cards">

    <div className="col-md-12 card-bodys p-3">
    <h6 style={{color:"gray",marginLeft:'11px',textAlign:"center"}}>My Tickets</h6>

          {/* <select
        name="status-select"
        id="status-select"
        className="mt-3 mr-3 custom-select"
        value={selectedStatus}
        onChange={handleChange} 
      >
        {statuses1.map((status, index) => (
          <option key={index} value={status}>
            {status}
          </option>
        ))}
      </select> */}
      
      <div className="select-container">
        <label style={{ fontWeight: "600", fontFamily: "Poppins" }}>
          Time Frame:
        </label>
        <select
          name="status-select"
          id="status-select"
          className="mt-3 mr-3 custom-select"
          value={selectedStatus}
          onChange={handleChange}
          style={{ marginLeft: "10px" }}
        >
          {statuses1.map((status, index) => (
            <option key={index} value={status}>
              {status}
            </option>
          ))}
        </select>
      </div>
          
      <Chart options={options} series={series} type="heatmap" height={350} />
    
    </div>
    </div>

  );
};

export default TicketsHeatMap;
