import React from "react";
import Pft from "../../assets/images/prof.png";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faIdBadge,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "../Chatpage/chatpage.css";
import ImageUser from "../../assets/images/imageuserimage.png";

function Profile() {
  const navigate = useNavigate();
  const logout = () => {
    navigate("/login");
    sessionStorage.clear();
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="card mt-5" style={{ width: "26rem" }}>
        <div>
          <img
            src={ImageUser}
            className=""
            alt="Profile"
            style={{ width: "40px", height: "40px" }}
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{sessionStorage.getItem("userName")}</h5>
          <div className="profile-body">
            <div className="profile-info">
              <FontAwesomeIcon icon={faUser} className="profile-icon" />
              <h5 className="profile-text">
                {sessionStorage.getItem("userName")}
              </h5>
            </div>
            <div className="profile-info">
              <FontAwesomeIcon icon={faIdBadge} className="profile-icon" />
              <h5 className="profile-text">
                {sessionStorage.getItem("userRole")}
              </h5>
            </div>
            <div className="profile-info">
              <FontAwesomeIcon icon={faEnvelope} className="profile-icon" />
              <h5 className="profile-text">{sessionStorage.getItem("email")}</h5>
            </div>
            <button className="btn logout-btn" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
