import React from "react";
import { Link } from "react-router-dom";






export const Uploadfile = () => {


  return (
    <div>
      {/* Title Section */}
      <div id="Divein_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title">
                <h2>Dive In: Your Ally Awaits</h2>
              </div>
            </div>
          </div>

          <div className="row chats_btn">
            <div className="col-md-12">
              <button>
                <Link to="/login" style={{ color: "#ffffff" }}>
                  Go To Chats &nbsp;
                </Link>
                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
              </button>{" "}
              <button>Get Pro</button>
            </div>
          </div>
          <div className="text_cnt">
            <p>
              {" "}
              Ready to see Ally in action? Simply upload a PDF to kickstart your
              interactive journey. Whether it's a technical manual, a customer
              service script, or an internal policy document, watch as Ally
              transforms it into an engaging, conversational experience. Get
              answers, gain insights, and explore the content like never before.
              Your Ally adventure begins with just a click.
            </p>
          </div>
        </div>
      </div>
      {/* Upload File Section */}
      <div id="upload">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="upload_sec">
                <form>
                  <div className="form-group file-area">
                    {/* <label for="images">
                    Images{" "}
                    <span>Your images should be at least 400x300 wide</span>
                  </label> */}
                    <input
                      type="file"
                      name="images"
                      id="images"
                      required="required"
                      multiple="multiple"
                      
                    />
                    <div className="file-dummy">
                      <div className="success">
                        Great, your files are selected. Keep on.
                      </div>
                      <i className="fa fa-upload" aria-hidden="true"></i>

                      <div className="default">
                        Please Upload files or Drag and Drop Files Here{" "}
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <button type="submit">Upload Files</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
