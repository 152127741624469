import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./Zendes.css"; // Import the CSS file

const ZendeskTickets = () => {
  const [loader, setLoader] = useState(false);
  const [ticketName, setTicketName] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [randomTickets, setRandomTickets] = useState<any[]>([]);
  const [persona, setPersona] = useState(sessionStorage.getItem("userRole"));

  useEffect(() => {
    generateRandomTickets();
    console.log(persona, "Persona");
  }, []);

  const showModals = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (sessionStorage.getItem("userRole") === "Ally-Support") {
      setTicketName("My Zendesk Tickets");
    } else {
      setTicketName("Your Tickets");
    }
  }, []);

  const generateRandomTickets = () => {
    const tickets = [
      {
        id: 1,
        subject: "Login Issue",
        description:
          "Login Issues on Gingr Web Application - The user is struggling to log in to the Gingr web application, encountering errors during the process, and seeking assistance to resolve the login issue promptly.",
        status: "Open",
        assignee: "Arjun Singh",
      },
      {
        id: 2,
        subject: "Payment not processed",
        description:
          "Unable to Book Appointments on Gingr Web Application - The user is unable to book appointments on the Gingr web application, leading to scheduling challenges and requiring immediate support to address the booking functionality issue.",
        status: "In Progress",
        assignee: "Rohan Desai",
      },
      {
        id: 3,
        subject: "Error on page",
        description:
          "Encountering Payment Processing Errors on Gingr Web Application - The user is facing payment processing errors while using the Gingr web application, affecting their ability to complete transactions smoothly and necessitating prompt resolution for seamless payment processing.",
        status: "Resolved",
        assignee: "Ananya Sharma",
      },
      {
        id: 4,
        subject: "Feature request",
        description:
          "Reporting Slow Performance on Gingr Web Application - The user has reported slow performance issues on the Gingr web application, detailing specific actions causing slowness and requesting assistance to improve the application's speed and performance for a better user experience.",
        status: "Open",
        assignee: "Aarav Gupta",
      },
      {
        id: 5,
        subject: "Bug report",
        description:
          "Seeking Assistance with Account Settings on Gingr Web Application - The user requires help with account settings on the Gingr web application, prompting a support request to address their account-related queries and ensure smooth navigation and customization of settings.",
        status: "In Progress",
        assignee: "Sneha Joshi",
      },
      {
        id: 5,
        subject: "Bug report",
        description:
          "Seeking Assistance with Account Settings on Gingr Web Application - The user requires help with account settings on the Gingr web application, prompting a support request to address their account-related queries and ensure smooth navigation and customization of settings.",
        status: "Open",
        assignee: "Support User 2",
      },
      {
        id: 5,
        subject: "Bug report",
        description:
          "Seeking Assistance with Account Settings on Gingr Web Application - The user requires help with account settings on the Gingr web application, prompting a support request to address their account-related queries and ensure smooth navigation and customization of settings.",
        status: "Resolved",
        assignee: "Support User 3",
      },
    ];
    setRandomTickets(tickets);
  };

  const handleTicketClick = (ticket: any) => {
    sessionStorage.setItem("selectedTicket", JSON.stringify(ticket));
    if (sessionStorage.getItem("selectedTicket")) {
      navigate("/dashboard/chat");
    }
  };

  return (
    <div className="mb-4">
      {loader ? (
        <div className="d-flex justify-content-center">Loading...</div>
      ) : (
        <div className="row">
          <div className="col-md-12 cards p-5">
          <h6 style={{color:"gray",marginLeft:'11px',textAlign:"center",marginBottom:"20px"}}>My Ticket Logs</h6>

            <div
              className=""
              style={{ padding: "0", margin: "0", position: "relative" }}
            >
              <div className="">
                {randomTickets.length > 0 ? (
                  <table className="table table-hover">
                    <thead
                      className="thead-light"
                      style={{
                        backgroundColor: "#f8f9fa",
                        color: "#212529",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      <tr>
                        <th
                          scope="col"
                          style={{ borderBottom: "2px solid #dee2e6" }}
                        >
                          Serial No.
                        </th>
                        <th
                          scope="col"
                          style={{ borderBottom: "2px solid #dee2e6" }}
                        >
                          Ticket Summary
                        </th>
                        <th
                          scope="col"
                          style={{ borderBottom: "2px solid #dee2e6" }}
                        >
                          Status
                        </th>
                        {persona === "Ally-Support" && (
                          <>
                            {" "}
                            <th
                              scope="col"
                              style={{ borderBottom: "2px solid #dee2e6" }}
                            >
                              Assignee
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {randomTickets.map((ticket, index) => (
                        <tr key={ticket.id} style={{ cursor: "pointer" }}>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {ticket.subject}
                          </td>
                          <td style={{textAlign:"center"}}>
                            <span
                              className={`badge ${ticket.status
                                .replace(" ", "-")
                                .toLowerCase()}`}
                            >
                              {ticket.status}
                            </span>
                          </td>
                          {persona === "Ally-Support" && (<>
                            <td style={{textAlign:"center"}}>
                            {sessionStorage.getItem("userName")}
                          </td></>)}

                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="mt-4">No tickets available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ticket Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body"></Modal.Body>
      </Modal>
    </div>
  );
};

export default ZendeskTickets;
