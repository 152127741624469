import React, { useEffect, useState } from "react";
import ceo from "../../assets/images/ce0.png";
import dev from "../../assets/images/Development.png";
import support from "../../assets/images/suppoort.png";
import support1 from "../../assets/images/csupport.png";
import logo from "../../assets/images/allylogo.png";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


import "./Landingpage.css";

import * as Icon from "react-feather";

const PersonaLandingPage = () => {
    const navigate = useNavigate()

  let [persona,setPersona] =useState("")
  
  useEffect(() => {
    const persona = sessionStorage.getItem("persona");
    console.log(persona, "Persona");
    if (!persona) {
      console.log("Persona data is missing");
    }
  }, []);
  return (
    <>
      <div className="container-fluid min-vh-100 d-flex justify-content-center align-items-center landing-page">
        <div className="container">
          <Col
            md={12}
            className="mb-5"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link
              to="/"
   
              style={{ textDecoration: "none" }}
            >
              <img
                src={logo}
                style={{ width: "150px", height: "50px", cursor: "pointer" }}
              ></img>
            </Link>
          </Col>
          <div className="mb-1 d-flex justify-content-center">
            <br></br>
            <h2 style={{ whiteSpace: "nowrap", marginBottom: "40px" }}>
              Continue As
            </h2>
          </div>
          <Row className="mb-5 mt-4 justify-content-center">
            <>
            <Col xs={12} md={6} lg={3} className="text-center" onClick={()=>{
                sessionStorage.setItem('persona','Ally_User')
                sessionStorage.setItem('name','Andrew')
                sessionStorage.setItem('login','Customer')
                navigate('/dashboard/homepage')
              }}>
                <Link to="" className="box1" style={{ textDecoration: "none" }}>
                  <Icon.UserCheck />
                  <h3 style={{ marginTop: "10px", marginLeft: "10px" }}>
                    Customer
                  </h3>
                </Link>
              </Col>
         



              <Col xs={12} md={6} lg={3} className="text-center" onClick={()=>{      
                      sessionStorage.setItem('persona','Ally_SuperUser')
                      sessionStorage.setItem('name','John Doe')
                      sessionStorage.setItem('login','Support')
                      navigate('/dashboard/homepage')
              }}>
                <div  className="box1" style={{ textDecoration: "none",cursor:"pointer" }}>
                  <Icon.Link />

                  <h3 style={{ marginTop: "10px", marginLeft: "10px" }}>
                   Support
                  </h3>
                </div>
              </Col>


          
              <Col xs={12} md={6} lg={3} className="text-center" onClick={
                ()=>{setPersona(persona = 'Leadership') 
                sessionStorage.setItem('persona','Ally_Admin')

              sessionStorage.setItem('name','Daniel')
              sessionStorage.setItem('login','Leadership')
              navigate('/dashboard/homepage')
              }}>
                <Link  className="box1" style={{ textDecoration: "none" }}>
                  <Icon.User />
                  <h3 style={{ marginTop: "10px", marginLeft: "10px" }}>
                    Leadership
                  </h3>
                </Link>
              </Col>
            </>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PersonaLandingPage;
